import { useEffect, useState } from "react";
import ProspectProcessTableBody from "./ProspectProcessTableBody";
import ProspectProcessTableHeader from "./ProspectProcessTableHeader";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { useAppSelector } from "hooks";
import { useDispatch } from "react-redux";
import UploadModal from "./component/UploadModal";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Button } from '@evabot/eva-react-core-library/dist/atoms/Button/Button';
import userService from "services/userService";
import reportService from "services/reportService";
import { SnackBar } from "@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar";
import stakeHolderTagService from "services/stakeholderTagService";
import { Backdrop, Box as Box1, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import Pagination from "components/Pagination/Pagination";
import { setDate } from "date-fns";

function ProspectProcessTable() {

    const [isUploadModalOpen, setUploadModalOpen] = useState(false);
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');
    const [loading, setLoading] = useState(false);
    const [tagListDataByType, setTagListDataByType] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalDataCount, setTotalDataCount] = useState(0);
    const [queuedYesterday, setQueuedYesterday] = useState(0);
    const [queuedToday, setQueuedToday] = useState(0);
    const [queuedTomorrowy, setQueuedTomorrow] = useState(0);
    const [userIdCountsWithNames, setuserIdCountsWithNames] = useState([]);

    const itemPerPageOptions = [
        { value: 10, label: "Per page: 10" },
        { value: 25, label: "Per page: 25" },
        { value: 50, label: "Per page: 50" }
    ];

    const handleUploadButtonClick = () => {
        setUploadModalOpen(true);
    };

    const handleUploadModalClose = () => {
        setUploadModalOpen(false);
    };

    const getProspectReportCountAsPerDate = async () => {
        const result = await reportService.getProspectReportCountAsPerDate();
        setQueuedYesterday(result?.yesterdayCount);
        setQueuedToday(result?.todayCount);
        setQueuedTomorrow(result?.tomorrowCount);
        setuserIdCountsWithNames(result?.UserIdCountsWithNames);   
    };

    const handleUploadList = async (values, fileData, ownerName, ownerEmail) => {
        setLoading(true);
        try {
          const transformedFileData = fileData.map(([email, linkedin_url]) => ({
            email,
            linkedin_url,
          }));
          const obj = { userId: values.user, tagName: values.tagName, ownerEmail : ownerEmail, ownerName : ownerName, userList: transformedFileData, listUploadFrom : "dashboard" };
          
          const response = await userService.addTagListByExcel(obj);
      
          if (response?.status === "success" && response?.data >= 1) {
            if(response?.count > 0){
                setSnackbarMessage('In Process and ' + response?.count + ' data is already processed or in queue');                
            }else{
                setSnackbarMessage('List is in process. Check after sometime');
            }
            setSnackbarType('success');
            setShowSnackBar(true);
            fetchTagList();
            setLoading(false);
          }else if(response?.status === "prospect size exceed"){
            setSnackbarMessage(response?.message);
            setSnackbarType('error');
            setShowSnackBar(true);
            setLoading(false);
          }
          else if(response?.count === 0 || response?.status === "Data already exist") {
            setSnackbarMessage('Data already added for the selected user');
            setSnackbarType('error');
            setShowSnackBar(true);
            setLoading(false);
          } 
          else {
            setSnackbarMessage('Error Adding List');
            setSnackbarType('error');
            setShowSnackBar(true);
            setLoading(false);
          }
        } catch (error) {
          console.error(error);
          setSnackbarMessage('Server Error');
          setSnackbarType('error');
          setShowSnackBar(true);
          setLoading(false);
        }
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };
    
    const handleItemsPerPageChange = (newItemsPerPage) => {
        setItemsPerPage(newItemsPerPage);
        setCurrentPage(1);
    };

    const fetchTagList = async () => {
        setLoading(true);
        try {
            const obj = ['excel'];
            stakeHolderTagService.getTagListByType(obj)
                .then((response) => {
                    setTagListDataByType(response?.data);
                    // setTotalDataCount(response.data.totalCount);
                    setLoading(false);
                })
        } catch (error) {
            console.error("Error fetching list");
            setLoading(false);
        }

    };

    useEffect(() => {
        fetchTagList();
        getProspectReportCountAsPerDate();
    }, []);

    const totalPages = Math.ceil(totalDataCount / itemsPerPage);

    return (
        <>
            <SnackBar
                iconName={"info"}
                message={snackbarMessage}
                setShowSnackBar={setShowSnackBar}
                show={showSnackBar}
                timeout={4000}
                anchor={"top-right"}
                type={snackbarType}
            />
            <Box>
                {loading && <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
                    open={true}
                >
                    <Icon name="loadingIcon" />
                </Backdrop>}
                <div>
                    <Flex justifyContent={"space-between"} alignItems="center" marginBottom={"20px"}>
                        <Flex>
                            <Box
                                padding="20px 30px"
                                boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)"
                                borderRadius="8px"
                                backgroundColor="#FFFFFF"
                                >
                                <Flex alignItems="center" flexDirection={"column"}>
                                    <Text fontSize={"24px"}>{queuedYesterday}</Text>
                                    <Text fontSize={"12px"}>Pending Yesterday</Text>
                                </Flex>
                            </Box>
                            <Box
                                padding="20px 30px"
                                boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)"
                                borderRadius="8px"
                                backgroundColor="#FFFFFF"
                                marginLeft={"10px"}
                                >
                                <Flex alignItems="center" flexDirection={"column"}>
                                    <Text fontSize={"24px"}>{queuedToday}</Text>
                                    <Text fontSize={"12px"}>Pending Today</Text>
                                </Flex>
                            </Box>
                            <Box
                                padding="20px 30px"
                                boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)"
                                borderRadius="8px"
                                backgroundColor="#FFFFFF"
                                marginLeft={"10px"}
                                >
                                <Flex alignItems="center" flexDirection={"column"}>
                                    <Text fontSize={"24px"}>{queuedTomorrowy}</Text>
                                    <Text fontSize={"12px"}>Pending Tomorrow</Text>
                                </Flex>
                            </Box>
                        </Flex>
                        <Flex>
                            <Button
                                size="s"
                                variant="secondary"
                                borderRadius={"15px"}
                                onClick={handleUploadButtonClick}
                            >
                                Upload List
                            </Button>
                        </Flex>
                    </Flex>
                </div>
                <div>
                    <Flex marginBottom={"10px"} gap={"10px"}>
                        <Box1 sx={{ marginBottom: '10px', gap: '10px', overflowX: 'auto', width: "60%" }}>
                            <TableContainer component={Paper}>
                                <Table aria-label="user data table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{fontSize : "12px"}}>Name</TableCell>
                                        <TableCell style={{fontSize : "12px"}}>Email</TableCell>
                                        <TableCell style={{fontSize : "12px"}}>Total Queued</TableCell>
                                        <TableCell style={{fontSize : "12px"}}>Created On</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {userIdCountsWithNames.length > 0 ? (
                                    userIdCountsWithNames.map((item, index) => (
                                        <TableRow key={index}>
                                        <TableCell style={{fontSize : "12px", overflowWrap : "break-word"}}>{item.name}</TableCell>
                                        <TableCell style={{fontSize : "12px", overflowWrap : "break-word"}}>{item.email}</TableCell>
                                        <TableCell style={{fontSize : "12px"}}>{item.count}</TableCell>
                                        <TableCell style={{fontSize : "12px", overflowWrap : "break-word"}}>{item.createdOn}</TableCell>
                                        </TableRow>
                                    ))
                                    ) : (
                                    <TableRow>
                                        <TableCell colSpan={4} align="center">No Data Available</TableCell>
                                    </TableRow>
                                    )}
                                </TableBody>
                                </Table>
                            </TableContainer>
                        </Box1>
                    </Flex>
                </div>
                <ProspectProcessTableHeader></ProspectProcessTableHeader>
                <ProspectProcessTableBody tagList={tagListDataByType} fetchList={fetchTagList} />
                {/* pagination */}
                <Box borderBottomLeftRadius={"8px"} borderBottomRightRadius={"8px"} background={"#fff"} padding={"10px 16px"}>
                    {/* <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                        onItemsPerPageChange={handleItemsPerPageChange}
                        itemPerPageOptions={itemPerPageOptions}
                    /> */}
                </Box>
                <UploadModal isOpen={isUploadModalOpen} onClose={handleUploadModalClose} handleListUpload={handleUploadList}/>
            </Box>
        </>
    );
}

export default ProspectProcessTable;
