import { useEffect, useState } from "react";
import { Icon as EvaLibraryIcon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { ToggleButton } from "@evabot/eva-react-core-library/dist/atoms/ToggleButton/ToggleButton";
import { Backdrop, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Chip, Box, Tooltip, MenuItem, Snackbar, Alert } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { axiosHelperCore } from "utils";
import { useAppSelector } from "hooks";
import ManagePersonasHeader from "./component/ManagePersonasHeader";
import Select from 'react-select';
import NoRecordFound from "./component/NoRecordFound";
import DeleteConfirmationPopup from "./component/DeleteConfirmationPopup";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import DeleteButton from "containers/FrameworkAndContext/component/DeleteButton";
import EditButton from "containers/FrameworkAndContext/component/EditButton";

const employeeRanges = [
    { value: "1-10", label: "1-10" },
    { value: "11-20", label: "11-20" },
    { value: "21-50", label: "21-50" },
    { value: "51-100", label: "51-100" },
    { value: "101-200", label: "101-200" },
    { value: "201-500", label: "201-500" },
    { value: "501-1000", label: "501-1000" },
    { value: "1001-2000", label: "1001-2000" },
    { value: "2001-5000", label: "2001-5000" },
    { value: "5001-10000", label: "5001-10000" },
    { value: "10000+", label: "10000+" }
];

const suggestedIndustries = [
    'Technology',
    'Finance',
    'Healthcare',
    'Education',
    'Retail',
    'Automotive',
    'Hospitality',
    'Construction',
    'Energy',
    'Entertainment',
    'Agriculture',
    'Manufacturing',
    'Telecommunications',
    'Pharmaceuticals',
    'Real Estate',
    'Transportation',
    'Aerospace',
    'Legal Services',
    'Public Sector',
    'Insurance',
    'Food and Beverage',
    'Media',
    'Consulting',
    'Nonprofit',
    'Biotechnology',
    'Mining',
    'Electronics',
    'Utilities',
    'Waste Management',
    'Logistics',
];

const suggestedJobTitles = [
    'Software Engineer',
    'Product Manager',
    'Data Scientist',
    'Marketing Specialist',
    'Sales Executive',
    'Operations Manager',
    'HR Specialist',
    'Business Analyst',
    'Customer Support Representative',
    'UX Designer',
    'Project Manager',
    'Accountant',
    'Financial Analyst',
    'Quality Assurance Engineer',
    'Chief Executive Officer',
    'Chief Financial Officer',
    'Chief Operating Officer',
    'Chief Technology Officer',
    'Administrative Assistant',
    'Graphic Designer',
    'Network Administrator',
    'Legal Assistant',
    'Compliance Officer',
    'Content Writer',
    'Digital Marketing Manager',
    'Social Media Manager',
    'Event Planner',
    'Research Scientist',
    'Medical Doctor',
    'Pharmacist',
    'Laboratory Technician',
    'Supply Chain Manager',
    'Machine Operator',
    'Safety Officer',
    'Environmental Engineer',
];


function ManagePersonasContent() {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    type Severity = 'info' | 'error' | 'warning' | 'success';

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<Severity>('info');

    const [editMode, setEditMode] = useState(false);
    const [selectedPersona, setSelectedPersona] = useState(null);

    const [personaName, setPersonaName] = useState('');
    const [personaNameError, setPersonaNameError] = useState(false);

    const [titleInput, setTitleInput] = useState('');
    const [personaTitles, setPersonaTitles] = useState([]);
    const [personaExperience, setPersonaExperience] = useState({ min: '', max: '' });
    const [locationInput, setLocationInput] = useState('');
    const [personaLocations, setPersonaLocations] = useState([]);
    const [personaDescription, setPersonaDescription] = useState('');
    const [personaKeywords, setpersonaKeywords] = useState([]);
    const [industryInput, setIndustryInput] = useState('');
    const [personaIndustry, setpersonaIndustry] = useState([]);
    const [activated, setActivated] = useState();
    const [contextList, setContextList] = useState([]);
    const [personaCompanyEmployeeSize, setPersonaCompanyEmployeeSize] = useState([]);
    const [contextId, setContextId] = useState("");
    const [defaultContextId, setDefaultContextId] = useState("");
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [showSuggestions, setShowSuggestions] = useState(false);

    const [jobTitleSuggestions, setJobTitleSuggestions] = useState([]);
    const [showJobTitleSuggestions, setShowJobTitleSuggestions] = useState(false);
    const [highlightedJobTitleIndex, setHighlightedJobTitleIndex] = useState(0);
    
    const [personas, setPersonas] = useState([]);
    const [highlightedIndex, setHighlightedIndex] = useState(0);

    const [personaTitlesError, setPersonaTitlesError] = useState(false);
    const [personaIndustryError, setPersonaIndustryError] = useState(false);
    const [personaCompanySizeError, setPersonaCompanySizeError] = useState(false);
    
    const loggedInMemberDetails = useAppSelector((state) => state.profileState.profileData);

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleOpen = (persona = null) => {
        if (persona) {
            setEditMode(true);
            setSelectedPersona(persona);
            setPersonaName(persona?.personaName);
            setPersonaTitles(persona?.personaTitles);
            setPersonaExperience(persona?.personaExperience);
            setPersonaLocations(persona?.personaLocations);
            setpersonaKeywords(persona?.personaKeywords);
            setpersonaIndustry(persona?.personaIndustry);
            setPersonaCompanyEmployeeSize(persona?.personaCompanyEmployeeSize || []);
            setContextId(persona?.contextId);
        } else {
            setEditMode(false);
            clearForm();
        }
        setOpen(true);
    };

    const clearForm = () => {
        setPersonaName('');
        setPersonaTitles([]);
        setPersonaExperience({ min: '', max: '' });
        setPersonaLocations([]);
        setpersonaKeywords([]);
        setpersonaIndustry([]);
        setPersonaCompanyEmployeeSize([]);
        setPersonaNameError(false);
        setPersonaTitlesError(false);
        setPersonaIndustryError(false);
        setPersonaCompanySizeError(false);
        setContextId("")
    };

    const handleClose = () => {
        setOpen(false);
        clearForm();
    };

    const handleDeleteTitle = (titleToDelete) => {
        setPersonaTitles(personaTitles.filter(title => title !== titleToDelete));
    };

    const handleAddLocation = () => {
        if (locationInput.trim()) {
            setPersonaLocations([...personaLocations, locationInput]);
            setLocationInput('');
        }
    };

    const handleDeleteLocation = (locationToDelete) => {
        setPersonaLocations(personaLocations.filter(location => location !== locationToDelete));
    };

    const handleDeleteKeyword = (keywordToDelete) => {
        setpersonaKeywords(personaKeywords.filter(keyword => keyword !== keywordToDelete));
    };


    const filterJobTitleSuggestions = (input) => {
        return suggestedJobTitles.filter(title =>
            title.toLowerCase().includes(input.toLowerCase())
        );
    };

    const handleTitleKeyDown = (e) => {
        if (showJobTitleSuggestions) {
            if (e.key === 'ArrowDown') {
                setHighlightedJobTitleIndex((prevIndex) =>
                    prevIndex < jobTitleSuggestions.length ? prevIndex + 1 : 0
                );
            } else if (e.key === 'ArrowUp') {
                setHighlightedJobTitleIndex((prevIndex) =>
                    prevIndex > 0 ? prevIndex - 1 : jobTitleSuggestions.length
                );
            } else if (e.key === 'Enter') {
                e.preventDefault();
                if (highlightedJobTitleIndex < jobTitleSuggestions.length) {
                    handleAddTitle(jobTitleSuggestions[highlightedJobTitleIndex]);
                } else {
                    handleAddTitle(titleInput);
                }
            }
        }
    };

    const handleAddTitle = (title) => {
        if (title.trim()) {
            setPersonaTitles((prevTitles) => [...prevTitles, title]);
            setTitleInput('');
            setShowJobTitleSuggestions(false);
            setHighlightedJobTitleIndex(0);
        }
    };

    const handleAddIndustry = (industry) => {
        if (industry.trim()) {
          setpersonaIndustry((prevIndustries) => [...prevIndustries, industry]);
          setIndustryInput('');
          setShowSuggestions(false);
          setHighlightedIndex(0);
        }
    };

    const onKeyDown = (e) => {
        if (showSuggestions) {
          if (e.key === 'ArrowDown') {
            setHighlightedIndex((prevIndex) =>
              prevIndex < filteredSuggestions.length ? prevIndex + 1 : 0
            );
          } else if (e.key === 'ArrowUp') {
            setHighlightedIndex((prevIndex) =>
              prevIndex > 0 ? prevIndex - 1 : filteredSuggestions.length
            );
          } else if (e.key === 'Enter') {
            e.preventDefault();
            if (highlightedIndex < filteredSuggestions.length) {
              handleAddIndustry(filteredSuggestions[highlightedIndex]);
            } else {
              handleAddIndustry(industryInput);
            }
          }
        }
    };
    
    const filteredSuggestions = suggestedIndustries.filter(suggestion =>
        suggestion.toLowerCase().includes(industryInput.toLowerCase())
    );

    const handleDeleteIndustry = (industryToDelete) => {
        setpersonaIndustry(personaIndustry.filter(keyword => keyword !== industryToDelete));
    };

    const handleCompanySizeChange = (selectedOptions) => {
        setPersonaCompanyEmployeeSize(selectedOptions.map(option => option.value));
    };

    const handleOpenPopup = (persona) => {
        setIsPopupOpen(true);
        setSelectedPersona(persona);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    const handleSubmit = () => {
        setPersonaNameError(!personaName.trim());
        const isJobTitleEmpty = personaTitles.length === 0;
        const isIndustryEmpty = personaIndustry.length === 0;
        const isCompanySizeEmpty = personaCompanyEmployeeSize.length === 0;

        const atLeastOneFilled = !isJobTitleEmpty || !isIndustryEmpty || !isCompanySizeEmpty;

        setPersonaTitlesError(isJobTitleEmpty);
        setPersonaIndustryError(isIndustryEmpty);
        setPersonaCompanySizeError(isCompanySizeEmpty);

        const showSnackbar = (message: string, severity: Severity) => {
            setSnackbarMessage(message);
            setSnackbarSeverity(severity);
            setSnackbarOpen(true);
        };

        if (!atLeastOneFilled) {
            showSnackbar("At least one of 'Job Title', 'Industry', or 'Company Size' must be filled.", "error");
            return;
        }

        const isDuplicate = !editMode && personas.some((p) => p.personaName.toLowerCase() === personaName.toLowerCase());

        let matchedPersonaName = '';

        const isSubsetOrOverlap = personas.some((p) => {
            const titleOverlap = personaTitles.some(title =>
                p.personaTitles.some(existingTitle =>
                    existingTitle.toLowerCase() === title.toLowerCase()
                )
            );
        
            const industryOverlap = personaIndustry.some(industry =>
                p.personaIndustry.some(existingIndustry =>
                    existingIndustry.toLowerCase() === industry.toLowerCase()
                )
            );
        
            const companySizeOverlap = personaCompanyEmployeeSize.some(size =>
                p.personaCompanyEmployeeSize.some(existingSize =>
                    existingSize.toLowerCase() === size.toLowerCase()
                )
            );
        
            if (titleOverlap && industryOverlap && companySizeOverlap) {
                matchedPersonaName = p.personaName;
                return true;
            }
        
            return false;
        });

        if (isDuplicate) {
            showSnackbar("A persona with the same name already exists.", "error");
            return;
        }

        if (isSubsetOrOverlap) {
            showSnackbar(`A persona with similar characteristics (${matchedPersonaName}) already exists.`, "warning");
            return;
        }

        const newPersona = {
            personaName,
            personaTitles,
            personaExperience,
            personaLocations,
            personaKeywords,
            personaIndustry,
            personaCompanyEmployeeSize,
            activated,
            contextId
        };

        let obj = {}

        if (editMode) {
            obj = {...newPersona, personaId : selectedPersona?.personaId, userId : loggedInMemberDetails?.userId, organizationId : loggedInMemberDetails?.organizationId}
        } else {
            obj = {...newPersona, userId : loggedInMemberDetails?.userId, organizationId : loggedInMemberDetails?.organizationId}
        }

        addOrUpdatePersona(obj);

        setOpen(false);
        clearForm();
    };

    const handleDeletePersona = async() => {
        setLoading(true);

        const obj = { personaId : selectedPersona?.personaId, deleted : true, userId : loggedInMemberDetails?.userId}

        const result = await axiosHelperCore({
            url: `rapport/user/deletePersona`,
            method: "POST",
            JSONData: obj
        });

        if(result?.data?.result){
            setPersonas(personas.filter((p) => p.personaId !== selectedPersona?.personaId));
        }
        
        setLoading(false);
    };

    const fetchPersonasList = async (organizationId = "") => {
        setLoading(true);
        const personaList = await axiosHelperCore({
            url: `rapport/user/fetchPersonasList`,
            method: "POST",
            JSONData: { organizationId: organizationId }
        });

        if(personaList?.data?.result?.length > 0 && personaList?.data?.status ){
            setPersonas(personaList?.data?.result);
        }
        setLoading(false);
    }

    const addOrUpdatePersona = async (obj) => {
        setLoading(true);
        const personaList = await axiosHelperCore({
            url: `rapport/user/addOrUpdatePersona`,
            method: "POST",
            JSONData: obj
        });

        if(personaList?.data?.result && personaList?.data?.status ){
            setPersonas(personaList?.data?.result);
            fetchPersonasList(loggedInMemberDetails?.organizationId);
        }

        setLoading(false);

    }

    const handleToggle = async(personaId, value) => {
        setLoading(true);
        setActivated(value);

        const obj = { personaId : personaId, activated : value, userId : loggedInMemberDetails?.userId}

        const result = await axiosHelperCore({
            url: `rapport/user/toggleActivePersona`,
            method: "POST",
            JSONData: obj
        })

        fetchPersonasList(loggedInMemberDetails?.organizationId);
        setLoading(false);
        
    };

    const handleContextValue = async(personaId, value) => {
        setLoading(true);
        setContextId(value?.value);
        const obj = { personaId : personaId, contextId : value?.value, userId : loggedInMemberDetails?.userId}

        const result = await axiosHelperCore({
            url: `rapport/user/handleContextValue`,
            method: "POST",
            JSONData: obj
        });

        if(result?.data?.result){
            fetchPersonasList(loggedInMemberDetails?.organizationId);
        }

        setLoading(false);
    }

    const handleDefaultContextValue = async(value) => {
        setLoading(true);
        setDefaultContextId(value?.value);
        const obj = {contextId : value?.value, organizationId : loggedInMemberDetails?.organizationId}

        const result = await axiosHelperCore({
            url: `rapport/user/handleDefaultContextValue`,
            method: "POST",
            JSONData: obj
        });

        setLoading(false);
    }

    const getDefaultContextValue = async() => {
        setLoading(true);
        const obj = {organizationId : loggedInMemberDetails?.organizationId}

        const result = await axiosHelperCore({
            url: `rapport/user/getDefaultContextValue`,
            method: "POST",
            JSONData: obj
        });

        if(result?.data?.result?.defaultContextId){
            setDefaultContextId(result?.data?.result?.defaultContextId);
        }

        setLoading(false);
    }

    const getFrameworkContextList = async () => {
        const result = await axiosHelperCore({
            url: `rapport/user/getFrameworkContextList`,
            method: "POST"
        });
        setContextList([{
            label: "None",
            value: "",
        }, 
        ...result?.data?.data?.contexts
            ?.filter(item => item.type === 'email' || item.type === 'linkedin')
            .map(item => ({
                label: item.name,
                value: item.contextId,
                description: item.context
            }))
        ]);
    };

    useEffect(() => {
        if(loggedInMemberDetails?.organizationId){
            fetchPersonasList(loggedInMemberDetails?.organizationId);
            getFrameworkContextList();
            getDefaultContextValue();
        }
    }, [])

    return (
        <>
            {loading && (
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
                    open={true}
                >
                    <EvaLibraryIcon name="loadingIcon" />
                </Backdrop>
            )}

            <Flex justifyContent={"flex-end"} gap="40px">
                <Box display={"flex"}>
                    <Text fontSize={"12px"} marginRight={"15px"} alignItems={"center"} display={"flex"} top={20}>Default Context : </Text>
                    <Box display={"flex"} marginRight={"15px"}>
                        <Tooltip title={defaultContextId ? contextList.find(option => option.value === defaultContextId)?.description || "No default context selected" : "No default context selected"} placement="bottom" arrow>
                            <span>
                            <Select
                                placeholder="Select Default Context"
                                options={contextList}
                                value={contextList.find(option => option.value === defaultContextId) || null}
                                onChange={(e) => handleDefaultContextValue(e)}
                                styles={{
                                    control: (provided, state) => ({
                                        ...provided,
                                        width: "202px",
                                        height: "48px",
                                        border: "6px",
                                        fontSize: "12px",
                                        top: 20
                                    }),
                                    menu: (provided) => ({
                                        ...provided,
                                        zIndex: 99999,
                                    }),
                                    menuPortal: (base) => ({ 
                                        ...base, 
                                        zIndex: 99999 ,
                                        marginTop: "25px",
                                    }),
                                }}

                            />
                            </span>
                        </Tooltip>    
                    </Box>    

                    <Button
                        variant="secondary" 
                        fontSize={"14px"} 
                        padding={"10px 10px"}
                        onClick={() => handleOpen(null)}
                        top={20}
                    >
                        Add Persona
                    </Button>
                    </Box>
            </Flex>    

            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle>{editMode ? 'Edit Persona' : 'Add New Persona'}</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Persona Name"
                        value={personaName}
                        onChange={(e) => setPersonaName(e.target.value)}
                        fullWidth
                        margin="normal"
                        required
                        error={personaNameError}
                        helperText={personaNameError ? "Persona name is required." : ""}
                    />

                    <Tooltip title="Add Job title and then press enter" placement="top" arrow>
                        <TextField
                            label="Add Job Title"
                            value={titleInput}
                            onChange={(e) => {
                                setTitleInput(e.target.value);
                                setShowJobTitleSuggestions(true);
                                setJobTitleSuggestions(filterJobTitleSuggestions(e.target.value));
                                setHighlightedJobTitleIndex(0);
                            }}
                            onKeyDown={handleTitleKeyDown}
                            onBlur={() => setShowJobTitleSuggestions(false)}
                            fullWidth
                            margin="normal"
                            InputProps={{
                                startAdornment: (
                                    <Box sx={{ display: 'flex', gap: '5px', marginRight: '8px' }}>
                                        {personaTitles.map((title, index) => (
                                            <Chip
                                                key={index}
                                                label={title}
                                                onDelete={() => handleDeleteTitle(title)}
                                                style={{
                                                    border: '1px solid rgb(0, 148, 227)',
                                                    color: 'rgb(0, 148, 227)',
                                                    backgroundColor: 'white',
                                                    fontSize: '12px'
                                                }}
                                                sx={{
                                                    '& .MuiChip-deleteIcon': {
                                                        display: 'none',
                                                    },
                                                    '&:hover .MuiChip-deleteIcon': {
                                                        display: 'inline',
                                                        color: 'white',
                                                    },
                                                    '&:hover': {
                                                        backgroundColor: 'rgb(0, 148, 227) !important',
                                                        color: 'white !important',
                                                        borderColor: 'transparent',
                                                    }
                                                }}
                                            />
                                        ))}
                                    </Box>
                                )
                            }}
                        />
                    </Tooltip>
                    {showJobTitleSuggestions && (
                        <Box sx={{
                            position: 'relative',
                            backgroundColor: '#e9f2ff',
                            boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
                            zIndex: 99,
                            maxHeight: '150px',
                            overflowY: 'auto'
                        }}>
                            {jobTitleSuggestions.map((suggestion, index) => (
                                <MenuItem
                                    key={index}
                                    selected={index === highlightedJobTitleIndex}
                                    onClick={() => handleAddTitle(suggestion)}
                                    onMouseEnter={() => setHighlightedJobTitleIndex(index)}
                                >
                                    {suggestion}
                                </MenuItem>
                            ))}
                        </Box>
                    )}


                    <Box display="flex" justifyContent="space-between" mt={2} gap={2}>
                        <TextField
                            label="Minimum Experience (Years)"
                            type="number"
                            value={personaExperience.min}
                            onChange={(e) => setPersonaExperience({ ...personaExperience, min: e.target.value })}
                            margin="normal"
                            fullWidth
                        />
                        <TextField
                            label="Maximum Experience (Years)"
                            type="number"
                            value={personaExperience.max}
                            onChange={(e) => setPersonaExperience({ ...personaExperience, max: e.target.value })}
                            margin="normal"
                            fullWidth
                        />
                    </Box>

                    <Tooltip title="Add Location and then press enter" placement="top" arrow>    
                        <TextField
                            label="Add Location"
                            value={locationInput}
                            onChange={(e) => setLocationInput(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && handleAddLocation()}
                            fullWidth
                            margin="normal"
                            InputProps={{
                                startAdornment: (
                                  <Box sx={{ display: 'flex', gap: '5px', marginRight: '8px' }}>
                                    {personaLocations.map((location, index) => (
                                      <Chip
                                        key={index}
                                        label={location}
                                        onDelete={() => handleDeleteLocation(location)}
                                        style={{
                                          border: '1px solid rgb(0, 148, 227)',
                                          color: 'rgb(0, 148, 227)',
                                          backgroundColor : "white",
                                          fontSize : "12px"
                                        }}
                                        sx={{
                                            '& .MuiChip-deleteIcon': {
                                              display: 'none',
                                            },
                                            '&:hover .MuiChip-deleteIcon': {
                                              display: 'inline',
                                              color: 'white',
                                            },
                                            '&:hover': {
                                              backgroundColor: 'rgb(0, 148, 227) !important',
                                              color: 'white !important',
                                              borderColor: 'transparent',
                                            }
                                        }}
                                      />
                                    ))}
                                  </Box>
                                )
                            }}
                        />
                    </Tooltip>

                    <Tooltip title="Add Industry and then press enter" placement="top" arrow>   
                    <TextField
                        label="Add Industry"
                        value={industryInput}
                        onChange={(e) => {
                            setIndustryInput(e.target.value);
                            setShowSuggestions(true);
                            setHighlightedIndex(0);
                        }}
                        onKeyDown={onKeyDown}
                        onBlur={() => setShowSuggestions(false)}
                        fullWidth
                        margin="normal"
                        InputProps={{
                            startAdornment: (
                              <Box sx={{ display: 'flex', gap: '5px', marginRight: '8px' }}>
                                {personaIndustry.map((industry, index) => (
                                  <Chip
                                    key={index}
                                    label={industry}
                                    onDelete={() => handleDeleteIndustry(industry)}
                                    style={{
                                      border: '1px solid rgb(0, 148, 227)',
                                      color: 'rgb(0, 148, 227)',
                                      backgroundColor : "white",
                                      fontSize : "12px"
                                    }}
                                    sx={{
                                        '& .MuiChip-deleteIcon': {
                                          display: 'none',
                                        },
                                        '&:hover .MuiChip-deleteIcon': {
                                          display: 'inline',
                                          color: 'white',
                                        },
                                        '&:hover': {
                                          backgroundColor: 'rgb(0, 148, 227) !important',
                                          color: 'white !important',
                                          borderColor: 'transparent',
                                        }
                                    }}
                                  />
                                ))}
                              </Box>
                            )
                        }}
                    />
                    </Tooltip>
                    {showSuggestions && (
                        <Box sx={{
                            position: 'relative',
                            backgroundColor: '#e9f2ff',
                            boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
                            zIndex: 99,
                            maxHeight: '150px',
                            overflowY: 'auto'
                        }}>
                            {filteredSuggestions.map((suggestion, index) => (
                                <MenuItem
                                    key={index}
                                    selected={index === highlightedIndex}
                                    onClick={() => handleAddIndustry(suggestion)}
                                    onMouseEnter={() => setHighlightedIndex(index)}
                                >
                                    {suggestion}
                                </MenuItem>
                            ))}
                        </Box>
                    )}

                    <Tooltip title="Add Description" placement="top" arrow>
                    <TextField
                        label="More nuanced persona? Add description here:"
                        value={personaDescription}
                        onChange={(e) => setPersonaDescription(e.target.value)}
                        fullWidth
                        margin="normal"
                        multiline
                        rows={3}
                        placeholder="Sales leader should have previously worked as either SDR or an SDR manager."
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    </Tooltip>

                    <label htmlFor="select-company-size" style={{ margin: '15px 0 5px 0', display: 'block', fontWeight : "300", fontSize : "14px" }}>
                        Select Company Size
                    </label>   
                    <Select
                        isMulti
                        id='select-company-size'
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={employeeRanges}
                        name="menu"
                        menuPlacement='top'
                        placeholder="Select Company Size"
                        value={employeeRanges.filter((option) =>
                            personaCompanyEmployeeSize.includes(option.value)
                        )}
                        closeMenuOnSelect={false}
                        onChange={handleCompanySizeChange}
                        styles={{
                            control: (provided: Record<string, unknown>, state: any) => ({
                                ...provided,
                                padding: '8px 0',
                                margin : "15px 0",
                                fontWeight : "300", 
                                fontSize : "14px"
                            }),
                        }}
                    />

                    <label htmlFor="select-context" style={{ margin: '15px 0 5px 0', display: 'block', fontWeight : "300", fontSize : "14px" }}>
                        Select Context
                    </label>    
                    <Select
                        id="select-context"
                        placeholder="Select Context"
                        options={contextList}
                        value={contextList.find(option => option.value === contextId) || null}
                        onChange={(e) => setContextId(e.value)}
                        styles={{
                            control: (provided: Record<string, unknown>, state: any) => ({
                                ...provided,
                                padding: '8px 0',
                                margin : "15px 0",
                                fontWeight : "300", 
                                fontSize : "14px"
                            }),
                            menu: (provided) => ({
                                ...provided,
                                zIndex: 99999,
                            }),
                            menuPortal: (base) => ({ 
                                ...base, 
                                zIndex: 99999,
                            }),
                        }}
                        menuPosition={'absolute'}
                        menuPlacement={'auto'}
                        menuPortalTarget={document.body}

                    />
                    {contextId && <div style={{ fontSize: "10px", backgroundColor: "#DCF9FF", color: "#57585C", display: 'flex', alignItems: 'center', gap: '10px', marginTop: "10px", border: "1px solid #0094E3", padding: '5px', borderRadius: '5px' }}>
                            { contextList.find(cx => cx.value === contextId)?.description}
                    </div>}

                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackbar}
                        color="red"
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
                        {snackbarMessage}
                        </Alert>
                    </Snackbar>


                </DialogContent>

                <DialogActions sx={{"padding" : "25px"}}>
                    <Button onClick={handleClose} variant="secondary" fontSize={"14px"} padding={"10px 10px"}>Cancel</Button>
                    <Button onClick={handleSubmit} variant="primary" fontSize={"14px"} padding={"10px 10px"}>{editMode ? 'Save' : 'Submit'}</Button>
                </DialogActions>
            </Dialog>

            <Box mt={10} zIndex={9}>
                {personas?.length > 0 &&<div className="flex flex-col">
                    <ManagePersonasHeader />
                    {personas.map((persona, index) => (
                        <Flex
                            padding={"15px"}
                            fontSize="12px"
                            fontWeight={500}
                            position="sticky"
                            zIndex={0}
                            backgroundColor={index % 2 == 0 ? "#FFF" : "rgb(249, 249, 249)"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            key={persona?.personaId}
                        >
                            <Flex padding={"0 5px"} textWrap="balance" width="5%" >
                                {index + 1}
                            </Flex>
            
                            <Flex style={{ "wordBreak": "break-word" }} padding={"0 5px"} width="13%">
                                {persona?.personaName}
                            </Flex>
                            <Flex style={{ "wordBreak": "break-word" }} padding={"0 5px"} width="22%" >
                                <Tooltip title={persona?.contextId ? contextList.find(option => option.value === persona?.contextId)?.description || "No context selected" : "No context selected"} placement="bottom" arrow>
                                    <span>
                                        <Select
                                            placeholder="Select Context"
                                            options={contextList}
                                            value={contextList.find(option => option.value === persona?.contextId) || null}
                                            onChange={(e) => handleContextValue(persona?.personaId, e)}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    width: "202px",
                                                    height: "48px",
                                                    border: "1px solid lightgrey",
                                                    fontSize: "12px",
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    zIndex: 99999,
                                                }),
                                                menuPortal: (base) => ({ 
                                                    ...base, 
                                                    zIndex: 99999,
                                                    marginTop: "-15px" 
                                                }),
                                            }}
                                            menuPosition={'absolute'}
                                            menuPlacement={'auto'}
                                            menuPortalTarget={document.body}

                                        />
                                    </span>
                                </Tooltip>
                            </Flex>
                            <Flex style={{ "wordBreak": "break-word" }} padding={"0 5px"} width="13%" >
                                {persona?.updatedBy}
                            </Flex>
                            <Flex style={{ "wordBreak": "break-word" }} padding={"0 5px"} width="12%" >
                                {persona?.updatedOn}
                            </Flex>
                            <Flex style={{ "wordBreak": "break-word" }} justifyItems={"center"} justifyContent={"center"} padding={"0 5px"} gap={"10px"} width="15%" >
                                <Tooltip title={!persona?.contextId ? "Select the context to enable the persona" : "Activate/Deactivate Persona"} placement="top" arrow>
                                    <span>    
                                        <ToggleButton
                                            value={persona?.activated}
                                            name='isActive'
                                            variant="tertiary"
                                            disabled={!persona?.contextId ? true : false}
                                            onChange={() => handleToggle(persona.personaId, !persona.activated)}
                                        />
                                    </span>
                                </Tooltip>
                            
                            </Flex>
                            <Flex style={{ "wordBreak": "break-word" }} padding={"0 5px"} gap={"10px"} width="20%">
                                <Tooltip title="Edit Persona" placement="top" arrow>
                                    <EditButton onClick={() => handleOpen(persona)}/>
                                </Tooltip>
                                <Tooltip title="Delete Persona" placement="top" arrow>
                                    <DeleteButton onClick={() => handleOpenPopup(persona)}/>
                                </Tooltip>
                            </Flex>
                        </Flex>
                    ))}
                </div>}
                {personas?.length === 0 && <NoRecordFound />}
                <DeleteConfirmationPopup
                    personaName={selectedPersona?.personaName}
                    onDelete={handleDeletePersona}
                    isOpen={isPopupOpen}
                    onClose={handleClosePopup}
                />
            </Box>
        </>
    );
}

export default ManagePersonasContent;
