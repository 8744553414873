import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { setOrgId, setTagViewVisibility, setTabViewMemberData } from './store/memberListingReducer'
import { ErrorMessage, Form, Formik } from 'formik';
import { FormikInput } from "@evabot/eva-react-core-library/dist/atoms/FormikInput/FormikInput";
import { Flex } from '@evabot/eva-react-core-library/dist/atoms/Flex/Flex';
import { Button } from '@evabot/eva-react-core-library/dist/atoms/Button/Button';
import { Box } from '@evabot/eva-react-core-library/dist/atoms/Box/Box';
import { ToggleButton } from '@evabot/eva-react-core-library/dist/atoms/ToggleButton/ToggleButton';
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { Tag } from '@evabot/eva-react-core-library/dist/molecules/Tag/Tag';
import userService from 'services/userService';
import { SnackBar } from '@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar';
import * as yup from 'yup';
import { ModalWrapper } from '@evabot/eva-react-core-library/dist/molecules/ModalWrapper/ModalWrapper';
import Select from 'react-select';
import MemberListInModal from './component/MemberListInModal';
import DeleteConfirmationModal from './component/DeleteConfirmationModal';
import { useAppSelector } from 'hooks';
import moment from 'moment';
import LinkedInIcon from "assets/icons/LinkedInIcon";
import { title } from 'process';
import { FormikTextArea } from '@evabot/eva-react-core-library/dist/atoms/FormikTextArea/FormikTextArea';
import { axiosHelperCore } from 'utils';
var md5 = require('md5');


const EditMemberSchema = yup.object().shape({
    userType: yup.string().required("Please select an User Type."),
    firstName: yup.string().required("First Name is required."),
    lastName: yup.string(),
    email: yup
        .string()
        .trim()
        .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/, "Invalid email address.")
        .required("Email is required."),
});

const MenuValue = [
    { value: "analytics", label: "Home" },
    { value: "integration", label: "Integration" },
    { value: "tag", label: "Tag" },
    { value: "nudges", label: "Nudges" },
    { value: "stakeholders", label: "Stakeholders" },
    { value: "history", label: "History" },
    { value: "teammanagement", label: "Team Management" },
    { value: "frameworkandcontext", label: "Framework / Context" },
    { value: "prospectprocess", label: "Excel Upload" },
    { value: "companyscorereport", label: "Accounts" },
    { value: "memberlisting", label: "Member Listing" },
    { value: "prospectreport", label: "Prospects" },
    { value: "automatedemail", label: "Automated Sequence" },
    { value: "otherintegrations", label: "Other Integrations" }
];

const usertypeOptions = [
    { value: "admin", label: "Admin" },
    { value: "sdr", label: "SDR" },
    { value: "ae", label: "AE" }
];

function MemberListingTableBodyField({ UserData, index, allMemberData, onRefetch, accountSequenceEnabled=false }) {
    const [hideModal, setHideModal] = useState(true);
    const [selectedMember, setSelectedMember] = useState(null);
    const [isActive, setIsActive] = useState(UserData?.isActive);
    const [originalIsActive, setOriginalIsActive] = useState(UserData?.isActive);
    const [selectedmenu, setSelectedMenu] = useState(UserData?.menu);
    const [usertype, setUserType] = useState(UserData?.userType);
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [showEmailCheckSnackBar, setShowEmailCheckSnackBar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');
    const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
    const [organizationFilteredList, setOrganizationFilteredList] = useState([]);
    const [showMemberListModal, setShowMemberListModal] = useState(false);
    const [isMemberDeleted, setMemberDeletedFlag] = useState(false);
    const [isSequenceAutomationOn, setIsSequenceAutomationOn] = useState(accountSequenceEnabled ? accountSequenceEnabled : UserData?.sequenceAutomationEnable);
    const [formikInitialValues, setFormikInitialValues] = useState({
        firstName: UserData?.firstName || '',
        lastName: UserData?.lastName || '',
        email: UserData?.email || '',
        title: '',
        companyIndustry: '',
        companyDescription: '',
        companyName: '',
        companyUrl: '',
        menu: UserData?.menu || [],
        isActive: UserData?.isActive || false,
        userId: UserData?.userId || false,
        userType: UserData?.userType || '',
    });


    const [userTypeOptionsDefaultValue, setUserTypeOptionsDefaultValue] = useState([]);

    const [menuOptionsDefaultValue, setMenuOptionsDefaultValue] = useState([]);

    const dispatch = useDispatch();

    const handleShowTagView = (userId, orgId, userEmail, pitch, persona) => {
        dispatch(setTagViewVisibility(true));
        let integration = [];
        if (UserData.email === userEmail) {
            integration = UserData.integration;
            pitch = UserData?.pitch;
            persona = UserData?.persona;

        }
        dispatch(setTabViewMemberData({ userId, userEmail, integration, pitch, persona }));
        dispatch(setOrgId(orgId));
    };

    const userSelected = useAppSelector(
        (state) => state.memberListingState?.tabViewState?.isTabViewMemberData
    )

    const memberListingData = useAppSelector(
        (state) => state.memberListingState?.memberListingData
    );

    const showTab = useAppSelector(
        (state) => state.memberListingState?.tabViewState?.isTagViewVisible
    );

    const loggedInUser = useAppSelector(
        (state) => state.profileState.profileData.userId
    )

    const onSuccessModal = () => {
        onRefetch();

    };

    const onMemberDeleted = (totalMembers: number) => {
        setMemberDeletedFlag(true);
        if (totalMembers === 0) {
            //  setSelectedMember(null);
            setTimeout(() => {
                setHideModal(true);
            }, 1);

        }
    }

    const confirmationModalHandler = () => {
        const sameOrgMemberList = organizationFilterData(UserData.organizationId);
        const adminArray = sameOrgMemberList.filter(item => item.userType === "admin");

        if (adminArray.length > 1 || sameOrgMemberList.length === 1 || UserData.userType !== 'admin') {
            setConfirmationModalVisible(!confirmationModalVisible);
        } else {
            setSnackbarMessage('Cannot delete the member. Either assign one member as "Admin" role or delete all other member');
            setSnackbarType('error');
            setShowSnackBar(true);
        }
    };

    const handleUpdateUser = async (index, updatedUserData) => {

        const userId = updatedUserData.userId;
        updatedUserData.adminUserId = userSelected.userId;

        if (!userId) {
            console.error("user id not found");
            return;
        }

        try {
            const updateMember = await userService.updateUser(updatedUserData);
            if (updateMember?.message) {
                setSnackbarMessage('Email Id Already Exist.');
                setShowEmailCheckSnackBar(true);
                setSnackbarType('error');
                setIsActive(originalIsActive);
            } else {
                setSnackbarMessage('Member has been updated successfully.');
                setShowSnackBar(true);
                setSnackbarType('success');
                setHideModal(true);
                onRefetch();
            }
        } catch (error) {
            console.error("Error updating member", error);
            setIsActive(originalIsActive);
        }

    };

    const passwordReset = (email) => {
        try {
            const data = { email: email };
            userService.resetPassword(data)
                .then((response) => {
                    if (response.success) {
                        setSnackbarMessage('The link has been sent to your email Id to reset your password.');
                        setShowSnackBar(true);
                    } else {
                        setSnackbarMessage('Error!!! Please try again');
                        setSnackbarType('error');
                        setShowSnackBar(true);
                    }
                })
        } catch (error) {
            console.log("Error sending password link", error);
        }
        setHideModal(true);
    }

    const handleSelectMember = (selectedMember) => {
        setFormikInitialValues({
            firstName: selectedMember.firstName || '',
            lastName: selectedMember.lastName || '',
            email: selectedMember.email || '',
            title: '',
            companyIndustry: '',
            companyDescription: '',
            companyName: '',
            companyUrl: '',
            menu: selectedMember.menu || MenuValue,
            isActive: selectedMember.isActive || false,
            userId: selectedMember.userId || false,
            userType: selectedMember.userType || '',
        });

        const userTypeVal: any = usertypeOptions.filter((t) => t.value === selectedMember.userType);
        setUserTypeOptionsDefaultValue(userTypeVal["0"]);
        if (!selectedMember?.menu) {
            setMenuOptionsDefaultValue(MenuValue);
        } else {
            setMenuOptionsDefaultValue(MenuValue.filter((item) => selectedMember.menu?.includes(item.value)));
        }
        setIsActive(selectedMember.isActive);

        setHideModal(false);

    };

    const resendInvitation = async () => {
        await userService.resendInvitation(UserData);
        setSnackbarMessage('Invitation sent successfully.');
        setSnackbarType('success');
        setShowSnackBar(true);
    }

    const openModalForm = (orgId, userData) => {
        setHideModal(false);
        setFormikInitialValues({
            firstName: userData.firstName || '',
            lastName: userData.lastName || '',
            email: userData.email || '',
            companyIndustry: UserData?.linkedinUserProfile && UserData?.linkedinUserProfile[0]?.data?.companyIndustry,
            companyDescription: UserData?.linkedinUserProfile && UserData?.linkedinUserProfile[0]?.data?.companyDescription,
            title: UserData?.linkedinUserProfile && UserData?.linkedinUserProfile[0]?.data?.title,
            companyName: UserData?.linkedinUserProfile && UserData?.linkedinUserProfile[0]?.data?.companyName,
            companyUrl: UserData?.linkedinUserProfile && UserData?.linkedinUserProfile[0]?.data?.companyUrl,
            menu: userData.menu || [],
            isActive: userData.isActive || false,
            userId: userData.userId || false,
            userType: userData.userType || '',
        });
        setIsActive(UserData.isActive);
        setSelectedMember(userData);

        setUserTypeOptionsDefaultValue(usertypeOptions.filter((t) => t.value === userData.userType));
        if (userData?.menu && userData?.menu.length > 0) {
            setMenuOptionsDefaultValue(MenuValue.filter((item) => userData.menu?.includes(item.value)));
        } else {
            setMenuOptionsDefaultValue(MenuValue);
        }
        const filterList = organizationFilterData(orgId);
        setOrganizationFilteredList(filterList);
        setShowMemberListModal(true);
    };

    const organizationFilterData = (orgId) => {
        return memberListingData.filter((item) => item.organizationId === orgId);
    }

    const getOrganizationAdminList = (orgId) => {
        return allMemberData.filter((item) => item.organizationId === orgId && item.userType === "admin");
    }

    const saveSequenceSettings = async (value, userId) => {
        const result: any = await axiosHelperCore({
            url: `rapport/user/saveSequenceUserSettings`,
            method: "POST",
            JSONData: { enabled: value, userId :  userId}
        });

        if(result?.data?.success){
            setShowSnackBar(true);
            setSnackbarMessage('Sequence setting saved successfully');
        }
    };



    useEffect(() => {

        if (!isMemberDeleted) {
            setOriginalIsActive(UserData?.isActive);
            setFormikInitialValues({
                firstName: UserData?.firstName || '',
                lastName: UserData?.lastName || '',
                email: UserData?.email || '',
                title: '',
                companyIndustry: '',
                companyDescription: '',
                companyName: '',
                companyUrl: '',
                menu: UserData?.menu || MenuValue,
                isActive: UserData?.isActive || false,
                userId: UserData?.userId || false,
                userType: UserData?.userType || '',
            });


            setUserTypeOptionsDefaultValue(usertypeOptions.filter(t => t.value === UserData?.userType));
            if (UserData?.menu && UserData?.menu.length > 0) {
                setMenuOptionsDefaultValue(MenuValue.filter((item) => UserData.menu?.includes(item.value)));
            } else {
                setMenuOptionsDefaultValue(MenuValue);
            }
        }
    }, [UserData]);

    const handleCopyDetails = async () => {
        try {
            const result: any = await axiosHelperCore({
                url: `rapport/user/getUpdatedToken`,
                method: "POST",
                JSONData: { tokenNumber: UserData?.token }
            });
            await navigator.clipboard.writeText(result.data.token);
            setSnackbarMessage('Token copied!');
            setShowSnackBar(true);
    
        } catch (error) {
            console.error('Error copying token:', error);
            setSnackbarMessage(JSON.stringify(error));
            setShowSnackBar(true);
        }
    }

    const formattedDate = UserData?.createdAt
        ? moment(UserData.createdAt).format('DD MMMM, YYYY')
        : '';
    return (
        <>
            <SnackBar
                iconName={"info"}
                message={snackbarMessage}
                setShowSnackBar={setShowSnackBar}
                show={showSnackBar}
                timeout={4000}
                anchor={"top-right"}
                type={snackbarType}
            />
            <Flex
                padding={"15px"}
                fontSize="12px"
                fontWeight={500}
                position="sticky"
                backgroundColor={index % 2 == 0 ? "#FFF" : "rgb(249, 249, 249)"}
                alignItems={"flex-start"}

            >
                <Flex padding={"0 5px"} textWrap="balance" width="5%" >
                    {index + 1}
                </Flex>
                <Flex style={{ "wordBreak": "break-word" }} padding={"0 5px"} width="20%" flexDirection={"column"} justifyContent={"flex-start"}>
                    <Flex flexDirection={"column"} gap='5px' textWrap="wrap">
                        <Flex gap={"5px"}>
                            <Flex>{(UserData?.firstName + ' ' + UserData?.lastName) || '-'}</Flex>
                            {UserData?.linkedinUserProfile && UserData?.linkedinUserProfile.length > 0 && <Flex> <a target='_blank' href={UserData?.linkedinUserProfile && UserData?.linkedinUserProfile[0]?.url}><LinkedInIcon /> </a></Flex>}
                            {/* {UserData?.linkedinUserCompany && UserData?.linkedinUserCompany.length > 0 && <Flex> <a target='_blank' href={UserData?.linkedinUserCompany && UserData?.linkedinUserCompany[0]?.url}><LinkedInIcon /> </a></Flex>} */}
                        </Flex>
                        <Flex>{UserData?.email || '-'}</Flex>

                        <Flex>App Version: {UserData?.appVersion || 'N/A'}</Flex>
                    </Flex>
                    <Flex>
                        <Flex>
                            {userSelected.userEmail === UserData?.email && <Tag
                                color="#fff"
                                bgColor="rgba(0, 0, 0, 0.7)"
                                text="You"
                            />}
                        </Flex>
                        <Flex marginTop={"5px"}>
                            {UserData?.isActive === false && UserData?.isDeleted === false && (
                                <Tag
                                    color="#000"
                                    bgColor="#FFD700"
                                    text="Pending"

                                />
                            )}

                            {UserData?.isActive === true && UserData?.isDeleted === false && (
                                <Tag
                                    color="#22C96F"
                                    bgColor="rgba(34, 201, 111, 0.1)"
                                    text="Active"
                                />
                            )}

                            {UserData?.isActive === false && UserData?.isDeleted === true && (
                                <Tag
                                    color="#FF0000"
                                    bgColor="rgba(255, 0, 0, 0.1)"
                                    text="Deactivated"
                                />
                            )}
                        </Flex>
                    </Flex>
                </Flex>
                <Flex style={{ "wordBreak": "break-word" }} padding={"0 5px"} width="10%" >
                    {UserData?.integration?.length > 0 ? "Yes" : "No"}
                </Flex>
                <Flex style={{ "wordBreak": "break-word" }} padding={"0 5px"} width="10%" >
                    {UserData?.email.split('@')[1] || '-'}
                </Flex>
                { showTab && <><Flex style={{ "wordBreak": "break-word" }} width="8%">
                    {UserData?.userType || "-"}
                </Flex>
                <Flex style={{ "wordBreak": "break-word" }} width="7%" >
                    <ToggleButton
                            value={isSequenceAutomationOn}
                            name='isActive'
                            variant="tertiary"
                            smallSize
                            disabled={accountSequenceEnabled}
                            onChange={(value) => { saveSequenceSettings(value, UserData?.userId); setIsSequenceAutomationOn(value) }} />
                </Flex></>}
                { !showTab && <Flex style={{ "wordBreak": "break-word" }} width="15%">
                    {UserData?.userType || "-"}
                </Flex>}
                <Flex padding={"0 5px"} width="10%" >
                    {UserData?.isActive ? 'Enabled' : 'Disabled'}
                </Flex>
                <Flex padding={"0 5px"} width="10%" >
                    {formattedDate}
                </Flex>
                <Flex padding={"0 5px"} width="20%" flexWrap={"wrap"}>
                    {
                        showTab &&

                        <>
                            <Button margin={"5px"} size={"s"} variant="secondary" borderRadius={"15px"} onClick={handleCopyDetails}>Copy Token</Button>
                            {(UserData?.isActive == true || UserData?.isDeleted == true) && (<Button margin={"5px"}
                                size="s"
                                variant="secondary"
                                borderRadius={"15px"}
                                onClick={() => { openModalForm(UserData?.organizationId, UserData) }}
                            >
                                Edit
                            </Button>)}

                            {UserData?.isActive == false && UserData?.isDeleted == false && (<Button margin={"5px"}
                                fontWeight={"bold"}
                                fontSize="13px"
                                variant="profileSendTreat"
                                size="s"
                                mt={"10px"}
                                onClick={resendInvitation}
                            // onClick={submitForm}
                            >
                                Resend Invitation
                            </Button>)}

                            {/* <Button
                                size="s"
                                variant="secondary"
                                borderRadius={"15px"}
                                marginLeft={"10px"}
                                disabled={UserData.userId === loggedInUser}
                                onClick={confirmationModalHandler}
                            >
                                Delete
                            </Button> */}
                        </>
                    }
                    {!showTab &&
                        <>
                            <Button
                                size="s"
                                variant="secondary"
                                borderRadius={"15px"}
                                margin={"5px"}
                                onClick={() => { handleShowTagView(UserData?.userId, UserData?.organizationId, UserData?.email, UserData?.pitch, UserData?.persona); }}
                            >
                                View
                            </Button>
                            <Button margin={"5px"} size="s" variant="secondary" borderRadius={"15px"} onClick={handleCopyDetails}>Copy Token</Button>
                        </>
                    }
                </Flex>
            </Flex>


            <ModalWrapper
                width="max-content"
                height={"650px"}
                hideModal={hideModal}
                setHideModal={() => {
                    setHideModal(true);
                }}
                bgcolor={"rgba(0, 0, 0, 0.8)"}
                padding="2%"
                overflow={"scroll"}
            >
                <SnackBar
                    iconName={"info"}
                    message={snackbarMessage}
                    setShowSnackBar={setShowEmailCheckSnackBar}
                    show={showEmailCheckSnackBar}
                    timeout={4000}
                    anchor={"top-right"}
                    type={snackbarType}
                />
                <Formik
                    enableReinitialize
                    initialValues={formikInitialValues}
                    onSubmit={(values) => {
                        console.log('Submitting edited data:', values);
                        values.isActive = isActive ? true : false;
                        handleUpdateUser(index, values);
                    }}
                    validationSchema={EditMemberSchema}
                >
                    {({ setFieldValue, values }) => (

                        <Form>
                            <Flex flexDirection="column">
                                <Flex>
                                    <Flex padding={"0 10px"} flexDirection="column">
                                        <Text variant="bodyRegular" fontWeight={"100"} paddingBottom={"5px"}>First Name</Text>
                                        <FormikInput
                                            name="firstName"
                                            placeholder="aereturn.profile.firstname"
                                        />
                                    </Flex>
                                    <Flex padding={"0 10px"} flexDirection="column">
                                        <Text variant="bodyRegular" fontWeight={"100"} paddingBottom={"5px"}>Last Name</Text>
                                        <FormikInput
                                            name="lastName"
                                            placeholder="aereturn.profile.lastname"
                                        />
                                    </Flex>
                                </Flex>
                                <Flex>
                                    <Flex flexDirection="column" gap={"2px"}>
                                        <Flex padding={"0 10px"} flexDirection="column" height={"90px"}>
                                            <Text variant="bodyRegular" fontWeight={"100"} paddingBottom={"5px"}>Email Address</Text>
                                            <FormikInput
                                                name="email"
                                                placeholder="aereturn.profile.email"
                                            />
                                        </Flex>
                                        <Flex padding={"5px 10px"} flexDirection="column">
                                            <Text variant="bodyRegular" fontWeight={"100"} paddingBottom={"5px"}>User Role</Text>
                                            <Box width={"360px"}>
                                                <Select
                                                    isDisabled={UserData.userType === "superadmin" || ((selectedMember?.userType === "admin" || UserData.userType === "superadmin") && getOrganizationAdminList(UserData.organizationId).length === 1)}
                                                    className="single-select"
                                                    placeholder="Select Role"
                                                    name="userType"
                                                    menuPlacement='top'
                                                    options={usertypeOptions}
                                                    value={UserData.userType === "superadmin" ? { value: "superadmin", label: "Super Admin" } : userTypeOptionsDefaultValue}
                                                    onChange={(userType) => {
                                                        setFieldValue('userType', userType ? userType.value : null);
                                                        setUserTypeOptionsDefaultValue(userType as any);
                                                        setUserType(userType);
                                                    }}

                                                    styles={{
                                                        control: (provided: Record<string, unknown>, state: any) => ({
                                                            ...provided,
                                                            width: '360px',
                                                        }),
                                                    }}

                                                />
                                                <ErrorMessage
                                                    name="userType"
                                                    component="div"
                                                    className="custom-error-message"
                                                />
                                            </Box>
                                        </Flex>
                                        <Flex padding={"0 10px"} flexDirection="column">
                                            <Text variant="bodyRegular" fontWeight={"100"} paddingBottom={"5px"}>Enable/Disable</Text>
                                            <Box display="inline">
                                                <ToggleButton
                                                    value={isActive}
                                                    name='isActive'
                                                    onChange={(value) => setIsActive(value)}
                                                />
                                                <Text fontSize={8}>Member profile <span style={{ fontWeight: "600" }}>{isActive ? 'Enabled' : 'Disabled'}</span></Text>
                                            </Box>
                                        </Flex>
                                    </Flex>
                                    <Flex padding={"0 10px"} flexDirection="column">
                                        <Text variant="bodyRegular" fontWeight={"100"} paddingBottom={"5px"}>Menu</Text>
                                        <Box width={"360px"}>
                                            {/* <Dropdown
                                                isMultiple
                                                padding={"7px 0px"}
                                                options={MenuValue}
                                                name="menu"
                                                checkboxLabel
                                                variantType="primary"
                                                value={menuOptionsDefaultValue}
                                                defaultValue={menuOptionsDefaultValue.length === 0 ? MenuValue : menuOptionsDefaultValue as any}
                                                // onChange={(menu) => {
                                                //     setFieldValue(menu);
                                                // }}
                                            /> */}
                                            <Select
                                                isMulti
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                options={MenuValue}
                                                name="menu"
                                                menuPlacement="auto"
                                                placeholder="Select Menu"
                                                value={menuOptionsDefaultValue}
                                                closeMenuOnSelect={false}
                                                onChange={(selectedMenu) => {
                                                    setFieldValue('menu', selectedMenu ? selectedMenu.map(item => item.value) : []);
                                                    setMenuOptionsDefaultValue(selectedMenu as any);
                                                }}
                                                styles={{
                                                    control: (provided: Record<string, unknown>, state: any) => ({
                                                        ...provided,
                                                        padding: '8px 0',
                                                        height:"230px"
                                                    }),
                                                }}
                                            />
                                        </Box>
                                    </Flex>
                                </Flex>
                                {formikInitialValues.title &&
                                    <><Flex justifyItems={"center"} alignItems={"center"}>
                                        <Flex padding={"0 10px"} flexDirection="column" alignItems={"flex-start"} justifyItems={"flex-start"}>
                                            <Text variant="bodyRegular" fontWeight={"100"} paddingBottom={"5px"}>Title</Text>
                                            <FormikInput
                                                name="title"
                                                placeholder="aereturn.profile.title"
                                            />
                                        </Flex>
                                        <Flex padding={"0 10px"} flexDirection="column" alignItems={"flex-start"} justifyItems={"flex-start"}>
                                            <Text variant="bodyRegular" paddingBottom={"5px"}>Company Industry</Text>
                                            <FormikInput
                                                name="companyIndustry"
                                                placeholder="aereturn.profile.companyIndustry"
                                            />
                                        </Flex>
                                    </Flex>
                                        <Flex justifyItems={"center"} alignItems={"center"}>
                                            <Flex padding={"0 10px"} flexDirection="column" alignItems={"flex-start"} justifyItems={"flex-start"}>
                                                <Text variant="bodyRegular" fontWeight={"100"} paddingBottom={"5px"}>Company Name</Text>
                                                <FormikInput
                                                    name="companyName"
                                                    placeholder="aereturn.profile.companyName"
                                                />
                                            </Flex>
                                            <Flex padding={"0 10px"} flexDirection="column" alignItems={"flex-start"} justifyItems={"flex-start"}>
                                                <Text variant="bodyRegular" fontWeight={"100"} paddingBottom={"5px"}>Company Url</Text>
                                                <FormikInput
                                                    name="companyUrl"
                                                    placeholder="aereturn.profile.companyUrl"
                                                />
                                            </Flex>
                                        </Flex>
                                    </>
                                }
                                {formikInitialValues.title && <Flex marginBottom={"15px"}>
                                    <Flex width={"100%"} padding={"0 10px"} flexDirection="column">
                                        <Text variant="bodyRegular" fontWeight={"100"} paddingBottom={"5px"}>Company Description</Text>
                                        <FormikTextArea width={"96%"}
                                            name="companyDescription"
                                            placeholder="aereturn.profile.companyDescription"
                                        />
                                    </Flex>
                                </Flex>}
                              
                                <Flex justifyContent={"space-between"} marginTop={"50px"}>
                                    <Flex justifyContent={"flex-start"} marginRight={1}>
                                        <Button type="button" onClick={() => { passwordReset(UserData?.email) }} marginLeft={2}>
                                            Reset Password
                                        </Button>
                                    </Flex>
                                    <Flex justifyContent={"flex-end"} marginRight={1}>
                                        <Button type="submit">Submit</Button>
                                        <Button variant="secondary" type="button"
                                            onClick={() => {
                                                setHideModal(true);
                                                setSelectedMember(null);
                                            }}
                                            marginLeft={2}>
                                            Cancel
                                        </Button>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Form>
                    )}
                </Formik>
            </ModalWrapper>

            {confirmationModalVisible && <DeleteConfirmationModal onClose={confirmationModalHandler} open={confirmationModalVisible} userId={UserData.userId} onSuccess={onSuccessModal} checkParent="" />}

        </>
    );
}

export default MemberListingTableBodyField;
