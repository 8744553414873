import Button from 'components/Elements/Button';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { TextField, Backdrop } from "@mui/material";
import { useSalesforceFields, useSaveSalesforceSettings, useSavedSalesforceFields } from 'utility/salesForce';
const SalesforceForm = ({ isDualMode, isFieldsAdded, mode, setShowForm, setConnectionText, setIsFieldsAdded }) => {
    const contactFieldsData = useSalesforceFields("Contact");
    const leadFieldsData = useSalesforceFields("Lead");
    const accountFieldsData = useSalesforceFields("Account");
    const { salesforceFields, changeSalesforceFields } = useSavedSalesforceFields({ dataSource: null, tagName: "evabot", tagField: null, callPrep: null, linkedinUrl: null, accountScore: null, accountSummary: null, prospectScore: null });
    const fieldOptions = salesforceFields.dataSource === "Contact" ? contactFieldsData.map(f => ({ label: f.label, value: f.name })) : (salesforceFields.dataSource === "Lead" ? leadFieldsData.map(f => ({ label: f.label, value: f.name })) : []);
    const { saveFormdata } = useSaveSalesforceSettings();
    const [showMessage, setShowMessage] = useState(false);
    const [IsError, setIsError] = useState(false);

    const handleDataSourceChange = (e) => {
        changeSalesforceFields({ dataSource: e.target.value, tagName: "evabot" });
    }

    useEffect(() => {
        const salesforceText = isFieldsAdded ? "Connected" : "Connection Pending";
        setConnectionText(salesforceText);
    }, [salesforceFields])

    useEffect(() => {
        setShowForm(true);
    }, []);


    const validateFields = () => {

        const fields = [
            salesforceFields?.tagField?.value,
            salesforceFields?.callPrep?.value,
            salesforceFields?.linkedinUrl?.value,
            salesforceFields?.prospectScore?.value,
            salesforceFields?.accountScore?.value + "account",
            salesforceFields?.accountSummary?.value + "account",

        ];

        console.log("fieldsfieldsfields", fields);

        const normalizedFields = fields.map(field =>
            (field !== undefined && field !== null && field.trim() !== '') ? field.trim().toLowerCase() : field
        );

        const filteredFields = normalizedFields.filter(field => field !== undefined && field !== null && field !== '');

        const uniqueFields = new Set(filteredFields);

        if (uniqueFields.size !== filteredFields.length) {
            return false;
        }

        return true;

    }

    const saveData = () => {

        // if (!validateFields()) {
        //     setIsError(true);
        //     const div = document.getElementById('scrollableDiv');
        //     div.scrollTop = 0;
        //     return;
        // }


        const formData = { salesforceFields };
        saveFormdata(formData);
        setShowMessage(true);
        setIsFieldsAdded(true);
        setShowForm(false);
        setConnectionText("Connected");
        setTimeout(() => {
            setShowMessage(false);
        }, 4000);
    }

    const cancel = () => {
        setIsFieldsAdded(true);
        setShowForm(false);
    }

    if (isDualMode && !mode)
        return (<>  </>)

    return (

        <div id='scrollableDiv' className='text-left' style={{ maxHeight: "40vh", scrollbarWidth: "none", overflowY: "auto", width: "90%", padding: "10px", paddingTop: "0", margin: "0 auto" }}>
            <div className='text-sm text-[#57585C]'>Salesforce Object to be tracked<sup className='text-red-500'>*</sup></div>
            <div className="flex text-sm gap-6 mt-2">
                <div className="flex items-center ">
                    <input type="radio" id="salesforce-contacts" onClick={handleDataSourceChange} checked={salesforceFields.dataSource === "Contact"} value="Contact" name="salesforce-objects" className="form-radio h-4 w-4 text-blue-500" />
                    <label className="ml-2 text-gray-700">Contacts</label>
                </div>
                <div className="flex items-center">
                    <input type="radio" id="salesforce-leads" onClick={handleDataSourceChange} checked={salesforceFields.dataSource === "Lead"} value="Lead" name="salesforce-objects" className="form-radio h-4 w-4 text-blue-500" />
                    <label className="ml-2 text-gray-700">Leads</label>
                </div>
            </div>


            {
                mode === "write" && (<>
                    {IsError && <div className='text-[red] text-[12px] mb-1'>All fields must have different values.</div>}
                    <div className='mt-4 mb-1'>Prospect Fields</div>
                    <div className='text-sm text-[#57585C] mt-4 mb-1'>Call Prep</div>
                    <div>
                        <Select className="text-sm"
                            menuPlacement='auto'
                            placeholder="Select"
                            name="field2"
                            value={salesforceFields?.callPrep?.value ? salesforceFields?.callPrep : null}
                            options={fieldOptions.filter(item => item.value !== salesforceFields?.tag?.value && item.value !== salesforceFields?.linkedinUrl?.value)}
                            onChange={(selectedField) => {
                                changeSalesforceFields({ ...salesforceFields, callPrep: selectedField });
                            }} />
                    </div>
                    <div className='text-sm text-[#57585C] mt-4 mb-1'>Prospect Account Score</div>
                    <div>
                        <Select className="text-sm"
                            menuPlacement='auto'
                            placeholder="Select"
                            name="field2"
                            value={salesforceFields?.prospectScore?.value ? fieldOptions.filter(a => a.value === salesforceFields?.prospectScore?.value) : null}
                            options={fieldOptions}
                            onChange={(selectedField) => {
                                changeSalesforceFields({ ...salesforceFields, prospectScore: selectedField });
                            }} />
                    </div>
                    <div style={{ marginTop: "15px" }} className='mt-4 mb-1'>Account Fields</div>
                    <div className='text-sm text-[#57585C] mt-4 mb-1'>Account Score</div>
                    <div className=''><Select
                        className="text-sm"
                        menuPlacement='auto'
                        placeholder="Select"
                        value={salesforceFields?.accountScore?.value ? accountFieldsData.filter(a => a.name === salesforceFields?.accountScore?.value)?.map(f => ({ label: f.label, value: f.name }))?.[0] : null}
                        name="field1"
                        options={accountFieldsData.map(f => ({ label: f.label, value: f.name }))}
                        onChange={(selectedField) => {
                            changeSalesforceFields({ ...salesforceFields, accountScore: selectedField });
                        }} />
                    </div>
                    <div className='text-sm text-[#57585C] mt-4 mb-1'>Account Summary</div>
                    <div>
                        <Select className="text-sm"
                            menuPlacement='auto'
                            placeholder="Select"
                            name="field2"
                            value={salesforceFields?.accountSummary?.value ? accountFieldsData.filter(a => a.name === salesforceFields?.accountSummary?.value)?.map(f => ({ label: f.label, value: f.name }))?.[0] : null}
                            options={accountFieldsData.map(f => ({ label: f.label, value: f.name }))}
                            onChange={(selectedField) => {
                                changeSalesforceFields({ ...salesforceFields, accountSummary: selectedField });
                            }} />
                    </div></>)
            }

            {
                mode === "read" && (<>
                    {IsError && <div className='text-[red] text-[12px] mb-1'>All fields must have different values.</div>}
                    <div className='mt-4 mb-1'>Prospect Fields</div>
                    <div className='text-sm text-[#57585C] mt-4 mb-1'>Tag Name</div>
                    <div>  <TextField
                        margin="normal"
                        fullWidth
                        style={{ padding: "0", margin: "0", marginBottom: "10px" }}
                        value={salesforceFields.tagName || ''}
                        onChange={(e) => changeSalesforceFields({ ...salesforceFields, tagName: e.target.value })}
                        variant="outlined"
                        inputProps={{ style: { padding: '10px', margin: 0 } }}
                    />
                    </div>
                    <div className='text-sm text-[#57585C] mt-2 mb-1'>Tags<sup className='text-red-500'>*</sup></div>
                    <div><Select
                        className="text-sm"
                        menuPlacement='auto'
                        placeholder="Select"
                        value={salesforceFields?.tagField?.value ? salesforceFields?.tagField : null}
                        name="field1"
                        options={fieldOptions}
                        onChange={(selectedField) => {
                            changeSalesforceFields({ ...salesforceFields, tagField: selectedField });
                        }} />
                    </div>

                    <div className='text-sm text-[#57585C] mt-4 mb-1'>LinkedIn Url</div>
                    <div>
                        <Select className="text-sm"
                            menuPlacement='auto'
                            placeholder="Select"
                            name="field3"
                            value={salesforceFields?.linkedinUrl?.value ? salesforceFields?.linkedinUrl : null}
                            options={fieldOptions}
                            onChange={(selectedField) => {
                                changeSalesforceFields({ ...salesforceFields, linkedinUrl: selectedField });
                            }} />
                    </div></>)
            }


            {
                ((!mode || mode === "all")) && (<>
                    {IsError && <div className='text-[red] text-[12px] mb-1'>All fields must have different values.</div>}
                    <div className='mt-4 mb-1'>Prospect Fields</div>
                    <div className='text-sm text-[#57585C] mt-4 mb-1'>Tag Name</div>
                    <div> <TextField
                        margin="normal"
                        fullWidth
                        style={{ padding: "0", margin: "0", marginBottom: "10px" }}
                        value={salesforceFields.tagName || ''}
                        onChange={(e) => changeSalesforceFields({ ...salesforceFields, tagName: e.target.value })}
                        variant="outlined"
                        inputProps={{ style: { padding: '10px', margin: 0 } }}
                    />
                    </div>
                    <div className='text-sm text-[#57585C] mt-2 mb-1'>Tags<sup className='text-red-500'>*</sup></div>
                    <div><Select
                        className="text-sm"
                        menuPlacement='auto'
                        placeholder="Select"
                        value={salesforceFields?.tagField?.value ? salesforceFields?.tagField : null}
                        name="field1"
                        options={fieldOptions.filter(item => item.value !== salesforceFields?.callPrep?.value && item.value !== salesforceFields?.linkedinUrl?.value)}
                        onChange={(selectedField) => {
                            changeSalesforceFields({ ...salesforceFields, tagField: selectedField });
                        }} />
                    </div>

                    <div className='text-sm text-[#57585C] mt-4 mb-1'>LinkedIn Url</div>
                    <div>
                        <Select className="text-sm"
                            menuPlacement='auto'
                            placeholder="Select"
                            name="field3"
                            value={salesforceFields?.linkedinUrl?.value ? salesforceFields?.linkedinUrl : null}
                            options={fieldOptions.filter(item => item.value !== salesforceFields?.tag?.value && item.value !== salesforceFields?.callPrep?.value)}
                            onChange={(selectedField) => {
                                changeSalesforceFields({ ...salesforceFields, linkedinUrl: selectedField });
                            }} />
                    </div>
                    <div className='text-sm text-[#57585C] mt-4 mb-1'>Call Prep</div>
                    <div>
                        <Select className="text-sm"
                            menuPlacement='auto'
                            placeholder="Select"
                            name="field2"
                            value={salesforceFields?.callPrep?.value ? salesforceFields?.callPrep : null}
                            options={fieldOptions.filter(item => item.value !== salesforceFields?.tag?.value && item.value !== salesforceFields?.linkedinUrl?.value)}
                            onChange={(selectedField) => {
                                changeSalesforceFields({ ...salesforceFields, callPrep: selectedField });
                            }} />
                    </div>
                    <div className='text-sm text-[#57585C] mt-4 mb-1'>Prospect Account Score</div>
                    <div>
                        <Select className="text-sm"
                            menuPlacement='auto'
                            placeholder="Select"
                            name="field2"
                            value={salesforceFields?.prospectScore?.value ? fieldOptions.filter(a => a.value === salesforceFields?.prospectScore?.value) : null}
                            options={fieldOptions}
                            onChange={(selectedField) => {
                                changeSalesforceFields({ ...salesforceFields, prospectScore: selectedField });
                            }} />
                    </div>

                    <div className='mt-4 mb-1'>Account Fields</div>
                   
                    <div className='text-sm text-[#57585C] mt-4 mb-1'>Account Score</div>
                    <div><Select
                        className="text-sm"
                        menuPlacement='auto'
                        placeholder="Select"
                        value={salesforceFields?.accountScore?.value ? accountFieldsData.filter(a => a.name === salesforceFields?.accountScore?.value)?.map(f => ({ label: f.label, value: f.name }))?.[0] : null}
                        name="field1"
                        options={accountFieldsData.map(f => ({ label: f.label, value: f.name }))}
                        onChange={(selectedField) => {
                            changeSalesforceFields({ ...salesforceFields, accountScore: selectedField });
                        }} />
                    </div>
                    <div className='text-sm text-[#57585C] mt-4 mb-1'>Account Summary</div>
                    <div>
                        <Select className="text-sm"
                            menuPlacement='auto'
                            placeholder="Select"
                            name="field2"
                            value={salesforceFields?.accountSummary?.value ? accountFieldsData.filter(a => a.name === salesforceFields?.accountSummary?.value)?.map(f => ({ label: f.label, value: f.name }))?.[0] : null}
                            options={accountFieldsData.map(f => ({ label: f.label, value: f.name }))}
                            onChange={(selectedField) => {
                                changeSalesforceFields({ ...salesforceFields, accountSummary: selectedField });
                            }} />
                    </div>
                </>)
            }




            {showMessage && <div className='m-3 text-[#38C088] text-sm'>
                Settings successfully saved!
            </div>}
            <div className='mt-5 text-center flex flex-wrap space-x-2 justify-center'>
                <Button variant={"salesforce"} text={setIsFieldsAdded ? "Save" : "Save"} onClick={saveData} disabled={!(salesforceFields?.dataSource)} />
                <Button variant={"secondary"} text={"Cancel"} onClick={cancel} />
            </div>
        </div>

    )
}

export default SalesforceForm;