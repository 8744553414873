import { useState, useEffect } from 'react';
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Backdrop } from "@mui/material";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import FilterComponent from "./component/FilterComponent";
import ApolloSearchTableHeaderAndBody from "./ApolloSearchTableHeaderAndBody";
import userService from 'services/userService';
import { useAppSelector } from 'hooks';
import NoRecordFound from './component/NoRecordFound';

const ApolloSearchTable = () => {

  const loggedInMemberDetails = useAppSelector((state) => state.profileState.profileData);
  const [loading, setLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState({companyDomain: "",
    customEmployeeRange: {min: '', max: ''},
    emailStatus: "Verified",
    employeeFilterOption: "predefined",
    employeeRanges: "",
    jobTitle: "",
    location: "",
    name: ""});
  const [contacts, setContacts] = useState([]);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [noRecord, setNoRecord] = useState(false);
  const recordsPerPage = 25; // Number of records per page

  // Function to call the API
  const fetchContacts = async (filters) => {
    setLoading(true);
    try {
        const obj = {
          data : {
            name: filters.name || "",
            location: filters.location || [],
            emailStatus: filters.emailStatus || [],
            companyDomain: filters.companyDomain || "",
            organizationLocation: filters.organizationLocation || [],
            personSeniorities: filters.personSeniorities || [],
            organizationEmployeesRanges: filters.organizationEmployeesRanges || [],
            jobTitle: filters.jobTitle || [],
            page,
            perPage: recordsPerPage,
          },
          userId :  loggedInMemberDetails?.userId
        };
        userService.fetchListFromApollo(obj)
            .then((response) => {
              if(response.success){
                if(response?.people?.length > 0){
                  setContacts([...response?.people]);
                  setTotalRecords(response?.pagination?.total_entries);
                }else{
                  setNoRecord(true);
                }
              }else{
                setNoRecord(true);
              }
              setLoading(false);
            })
    } catch (error) {
        console.error("Error fetching list");
        setLoading(false);
    }
  };


  useEffect(() => {

    const filters = {
      location: [selectedFilter?.location],
      emailStatus: [selectedFilter?.emailStatus],
      companyDomain: selectedFilter?.companyDomain,
      organizationLocation: [],
      personSeniorities: [],
      organizationEmployeesRanges: ['1, 10000'],
      jobTitle: [selectedFilter?.jobTitle],
      name: selectedFilter?.name
    };

    if(selectedFilter?.employeeFilterOption == 'predefined'){

      const ranges =  selectedFilter?.employeeRanges.split('-');

      filters.organizationEmployeesRanges = [`${ranges[0]},${ranges[1]}`];
    }

    if(selectedFilter?.employeeFilterOption == 'custom'){
      const customRangeMin = selectedFilter?.customEmployeeRange?.min;
      const customRangeMax = selectedFilter?.customEmployeeRange?.max;
      
      if (customRangeMin !== undefined && customRangeMax !== undefined) {
        filters.organizationEmployeesRanges = [`${customRangeMin},${customRangeMax}`];
      }
    }


    fetchContacts(filters);
  }, [page, selectedFilter]);

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
  };

  return (
    <>
      {loading && (
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }} open={true}>
          <Icon name="loadingIcon" />
        </Backdrop>
      )}
      <Flex width="100%" gap={"10px"} style={{ height: '100vh', overflow: 'hidden' }}>
        <Flex width="25%" marginBottom={"20px"}>
            <FilterComponent selectedFilter={selectedFilter} onFilterChange={handleFilterChange} />
        </Flex>
        <Flex width="75%" marginBottom={"20px"}>
          {!noRecord && <ApolloSearchTableHeaderAndBody contacts={contacts} totalRecords={totalRecords} page={page} setPage={setPage} /> }
          {noRecord && <NoRecordFound />}
        </Flex>
      </Flex>
    </>
  );
};

export default ApolloSearchTable;
