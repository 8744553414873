import { Box } from '@evabot/eva-react-core-library/dist/atoms/Box/Box';
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import userService from 'services/userService';
import { Backdrop } from "@mui/material";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { useEffect, useState } from 'react';
import { useAppSelector } from 'hooks';
import { Button } from '@evabot/eva-react-core-library/dist/atoms/Button/Button';
import { TextArea } from '@evabot/eva-react-core-library/dist/atoms/TextArea/TextArea';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { SnackBar } from '@evabot/eva-react-core-library/dist/atoms/Snackbar/SnackBar';
import { ToggleButton } from '@evabot/eva-react-core-library/dist/atoms/ToggleButton/ToggleButton';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';



const Settings = ({ userData }) => {

    const [showSnackBar, setShowSnackBar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarType, setSnackbarType] = useState('success');
    const [loading, setLoading] = useState(false);
    const [text, setText] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [callPrepEnabledOrNot, setCallPrepEnabledOrNot] = useState(null);
    const [salesforceData, setSalesforceData] = useState(null);
    const [sequenceAutomationData, setSequenceAutomationData] = useState(null);
    const [frameworks, setFrameworkData] = useState([]);
    const [contexts, setContextData] = useState([]);

    const viewMemberOrgId = useAppSelector((state) => state.memberListingState.tabViewState.isOrgId);

    const fetchCallPrep = (orgId) => {
        setLoading(true);
        const obj = { organizationId: orgId };
        try {
            userService.getCallPrep(obj)
                .then((response) => {
                    setText(response?.customField);
                    setLoading(false);
                })
        }
        catch (error) {
            console.error("Error fetching call prep", error);
        }
    };

    const getAccountDetails = (orgId) => {

        setLoading(true);

        const obj = { organizationId: orgId, userId: userData?.userId };
        try {
            userService.getAccountDetails(obj)
                .then((response) => {
                    setCallPrepEnabledOrNot(response?.accountDetails?.callPrepEnabled);
                    setSalesforceData(response?.accountDetails?.salesForceField);
                    setSequenceAutomationData(response?.accountDetails?.sequenceAutomation);
                    setFrameworkData(response?.frameworks);
                    setContextData(response?.contexts);
                    setLoading(false);
                })

        } catch (error) {
            console.error("Error fetching account details", error);
        }
    };

    const accountCallPrepSetting = (callPrepEnabledOrNotSetting) => {

        setLoading(true);

        const obj = { organizationId: viewMemberOrgId, callPrepEnabled : callPrepEnabledOrNotSetting  };
        try {
            userService.accountCallPrepSetting(obj)
                .then((response) => {
                    setCallPrepEnabledOrNot(response?.callPrepEnabled);
                    setLoading(false);
                })

        } catch (error) {
            console.error("Error fetching account details", error);
        }
    };

    const saveCallPrep = (orgId, text = "") => {
        setLoading(true);
        const obj = { organizationId: orgId, fieldName: text };
        try {
            userService.saveCallPrep(obj)
                .then((response) => {
                    setLoading(false);
                })
        } catch (error) {
            console.error("Error fetching call prep", error);
        }
    }


    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleRemove = () => {
        setText("");
        setIsEditing(false);
        saveCallPrep(viewMemberOrgId, "");
    };

    const handleSave = (newText) => {
        setText(newText);
        setIsEditing(false);
        saveCallPrep(viewMemberOrgId, newText);
    };

    useEffect(() => {
        if (viewMemberOrgId) {
            fetchCallPrep(viewMemberOrgId);
            getAccountDetails(viewMemberOrgId);
        }
    }, [viewMemberOrgId]);

    return (
        <>
            <SnackBar
                iconName={"info"}
                message={snackbarMessage}
                setShowSnackBar={setShowSnackBar}
                show={showSnackBar}
                timeout={4000}
                anchor={"top-right"}
                type={snackbarType}
            />
            {loading &&
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
                    open={true}
                >
                    <Icon name="loadingIcon" />
                </Backdrop>}
            <Box padding={"40px"} backgroundColor="white" border="1px solid #ddd" boxShadow="0px 0px 8px rgba(0,0,0,0.1)" borderRadius="4px">
                <Flex flexDirection={"column"} gap="25px">
                    <Flex flexDirection={"row"} width={"100%"} alignItems={"center"}>
                        <Flex width={"25%"} opacity={"0.5"}>Call Prep Custom Field</Flex>
                        {(text && !isEditing) ? (
                            <Flex width={"75%"} alignItems={"center"} justifyContent={"space-between"} style={{ border: "1px solid #d3d3d3", padding: "4px", borderRadius: "10px" }}>
                                <Flex padding={"10px"}>
                                    <p>{text}</p>
                                </Flex>
                                <Flex gap='8px' padding={"5px"} alignItems={"center"}>
                                    <EditIcon onClick={handleEdit} style={{ cursor: 'pointer', width: "20px" }} />
                                    <DeleteIcon onClick={handleRemove} style={{ cursor: 'pointer', width: "20px" }} />
                                </Flex>
                            </Flex>
                        ) : (
                            <Flex width={"75%"} gap='50px'>
                                {isEditing ? (
                                    <Flex gap='10px' alignItems={"center"}>
                                        <TextArea
                                            value={text}
                                            onChange={(e) => setText(e.target.value)}
                                            width={"385px"}
                                            height={"80px"}
                                        />
                                        <Button variant="secondary" onClick={() => handleSave(text)}>Save</Button>
                                        <Button variant="secondary" onClick={() => setIsEditing(false)}>Cancel</Button>
                                    </Flex>
                                ) : (
                                    <Button onClick={() => setIsEditing(true)}>Add Text</Button>
                                    // <AddIcon onClick={() => setIsEditing(true)} style={{ cursor: 'pointer' }} />
                                )}
                            </Flex>
                        )}
                    </Flex>
                    <Flex flexDirection={"row"} width={"100%"} alignItems={"center"}>
                        <Flex width={"25%"} opacity={0.5}>Call Prep Enable</Flex>
                        <Flex width={"75%"}>
                            <ToggleButton
                                value={callPrepEnabledOrNot}
                                name='isActive'
                                variant="tertiary"
                                onChange={() => {accountCallPrepSetting(!callPrepEnabledOrNot)}}
                            // disabled={loggedInUserId === UserData?.userId}
                            />
                        </Flex>
                    </Flex>
                    <Flex flexDirection={"row"} width={"100%"} alignItems={"center"}>
                        <Flex width={"25%"} opacity={0.5}>Salesforce Data Source</Flex>
                        <Flex width={"75%"}>
                            <Text>
                                {salesforceData?.dataSource || "N/A"}
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex flexDirection={"row"} width={"100%"} alignItems={"center"}>
                        <Flex width={"25%"} opacity={0.5}>Salesforce Tag Field Label</Flex>
                        <Flex width={"75%"}>
                            <Text>
                                {salesforceData?.tagField?.label || "N/A"}
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex flexDirection={"row"} width={"100%"} alignItems={"center"}>
                        <Flex width={"25%"} opacity={0.5}>Salesforce Tag Field Name</Flex>
                        <Flex width={"75%"}>
                            <Text>
                                {salesforceData?.tagField?.name || "N/A"}
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex flexDirection={"row"} width={"100%"} alignItems={"center"}>
                        <Flex width={"25%"} opacity={0.5}>Salesforce Call Prep Label</Flex>
                        <Flex width={"75%"}>
                            <Text>
                                {salesforceData?.callPrepField?.label || "N/A"}
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex flexDirection={"row"} width={"100%"} alignItems={"center"}>
                        <Flex width={"25%"} opacity={0.5}>Salesforce Call Prep Name</Flex>
                        <Flex width={"75%"}>
                            <Text>
                                {salesforceData?.callPrepField?.name || "N/A"}
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex flexDirection={"row"} width={"100%"} alignItems={"center"}>
                        <Flex width={"25%"} opacity={0.5}>Sequence Automation</Flex>
                        <Flex width={"75%"}>
                            <Text>
                                {sequenceAutomationData?.enabled?"enabled":"disabled" || "N/A"}
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex flexDirection={"row"} width={"100%"}>
                        <Flex width={"100%"}>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Tone</TableCell>
                                            <TableCell>Word Limit</TableCell>
                                            <TableCell>Framework</TableCell>
                                            <TableCell>Contexts</TableCell>
                                            <TableCell>status</TableCell>
                                            {/* <TableCell>Edit</TableCell> */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {sequenceAutomationData?.details?.map(d => (
                                            <TableRow >
                                                <TableCell>{d?.tone || 'N/A'}</TableCell>
                                                <TableCell>{d?.wordLimit || 'N/A'}</TableCell>
                                                <TableCell>{frameworks.find(f=>f.frameworkId===d?.frameworkId)?.name || "N/A"}</TableCell>
                                                <TableCell>{contexts.find(c=>c.contextId===d?.contextId)?.name || "N/A"}</TableCell>
                                                <TableCell>{d?.enabled?"enabled":"disabled" || "disbaled"}</TableCell>
                                                {/* <TableCell>
                                                         <Button onClick={() => console.log("hi")}>Edit</Button>
                                                     </TableCell> */}
                                            </TableRow>
                                        ))}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Flex>
                    </Flex>
                </Flex>
            </Box>
        </>
    );
};

export default Settings