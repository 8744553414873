import { Button } from '@evabot/eva-react-core-library/dist/atoms/Button/Button';
import { Flex } from '@evabot/eva-react-core-library/dist/atoms/Flex/Flex';
import HubspotForm from 'components/Integration/HubspotForm';
import OutreachForm from 'components/Integration/OutreachForm';
import SalesforceForm from 'components/Integration/SalesforceForm';
import SalesloftForm from 'components/Integration/SalesloftForm';

import React, { useEffect, useState } from 'react';
import Select from 'react-select';

export function IntegrationCard({ integration, setWriteModeIntegration, setReadModeIntegration, disconnectIntegration, dualMode }) {

    const readIntegration = integration?.connections?.find(c => c?.mode === "read");
    const writeIntegration = integration?.connections?.find(c => c?.mode === "write");


    const [showForm, setShowForm] = useState(integration.isShowForm);
    const [isFieldsAdded, setIsFieldsAdded] = useState(integration.isFieldsAdded);
    const [connectionText, setConnectionText] = useState(integration.isFieldsAdded ? "Connected" : "Connection Pending");
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        const modeValue = readIntegration?.type === integration?.type ? readIntegration?.mode : (writeIntegration?.type === integration?.type ? writeIntegration?.mode : "");
        setSelectedOption(modeValue ? { label: modeValue, value: modeValue } : null);
    }, [readIntegration, writeIntegration])


    return (<div style={{ display: "flex", background: "#fff", width: "25%", alignItems: "center", justifyContent: "center", justifyItems: "center", flexDirection: "column", gap: "30px", borderRadius: "12px", position: "relative", padding: "25px", paddingLeft: "0", paddingRight: "0" }}>

        {integration?.isConnected && <div style={{ padding: "2px", background: "#60C678", width: "100%", position: "absolute", top: "0", borderTopLeftRadius: "12px", borderTopRightRadius: "12px", color: "#fff", textAlign: "center", fontSize: "14px" }}>{connectionText}</div>}

        {showForm  ? (<div className='mb-3' style={{ width: "60px", display: "flex", flexWrap: "wrap", height: "30px", borderRadius: "9px", justifyContent: "center", alignItems: "center", marginTop: "10px" }}>
            {integration.icon}
        </div>) : (<div style={{ width: "149px", border: "1px solid #E8E9FD", display: "flex", flexWrap: "wrap", height: "119px", boxShadow: "0px 0px 5.5px 0px #0000001C", borderRadius: "9px", justifyContent: "center", alignItems: "center", marginTop: "10px" }}>
            {integration.icon}
        </div>)}




        {integration?.isConnected && dualMode?.enabled &&
            (<>
                <div >
                    <div className='text-sm text-[#57585C] mb-2'>Select Integration Mode</div>
                    <div><Select
                        className="text-sm"
                        menuPlacement='top'
                        placeholder="Select mode"
                        value={selectedOption}
                        isDisabled={selectedOption?.value}
                        name="field1"
                        options={[{ label: "read", value: "read" }, { label: "write", value: "write" }]}
                        onChange={(selectedOption) => {
                            const otherIntegration = integration?.connections?.filter(f => f.type !== integration.type)?.map(c => c.type)?.[0];
                            if (selectedOption.value == "read") {
                                setReadModeIntegration({ mode: "read", type: integration.type });
                                setWriteModeIntegration({ mode: "write", type: otherIntegration });
                            }

                            if (selectedOption.value == "write") {
                                setWriteModeIntegration({ mode: "write", type: integration.type });
                                setReadModeIntegration({ mode: "read", type: otherIntegration });
                            }

                        }} />
                    </div></div></>)
            // <div style={{ textAlign: "left", display: "flex", marginRight: "auto", paddingLeft: "10px" }}>
            //     <div className='text-sm text-[#57585C]'>Select Mode:&nbsp;</div>
            //     <div className='text-sm text-[#57585C]'>
            //         <label style={{ marginRight: "10px" }}>
            //             <input
            //                 type="radio"
            //                 value="read"
            //                 checked={selectedOption === 'read'}
            //                 onChange={handleOptionChange}
            //             />
            //             &nbsp;Read
            //         </label>

            //         <label>
            //             <input
            //                 type="radio"
            //                 value="write"
            //                 checked={selectedOption === 'write'}
            //                 onChange={handleOptionChange}
            //             />
            //             &nbsp;Write
            //         </label>
            //     </div>
            // </div>
        }
        {integration.type === "salesforce" && integration?.isConnected && (!isFieldsAdded || showForm) && <SalesforceForm isDualMode={dualMode?.enabled} mode={integration?.mode} isFieldsAdded={isFieldsAdded} setIsFieldsAdded={setIsFieldsAdded} setShowForm={(value: boolean) => { setShowForm(value) }} setConnectionText={setConnectionText} ></SalesforceForm>}

        {integration.type === "outreach" && integration?.isConnected && (!isFieldsAdded || showForm) && <OutreachForm isDualMode={dualMode?.enabled} mode={integration?.mode} isFieldAdded={isFieldsAdded} setIsFieldsAdded={setIsFieldsAdded} setShowForm={(value: boolean) => { setShowForm(value) }} setOutreachText={setConnectionText}></OutreachForm>}

        {integration.type === "salesloft" && integration?.isConnected && (!isFieldsAdded || showForm) && <SalesloftForm isDualMode={dualMode?.enabled} mode={integration?.mode} isFieldAdded={isFieldsAdded} setIsFieldsAdded={setIsFieldsAdded} setShowForm={(value: boolean) => { setShowForm(value) }} setSalesloftText={setConnectionText}></SalesloftForm>}

        {integration.type === "hubspot" && integration?.isConnected && (!isFieldsAdded || showForm) && <HubspotForm isDualMode={dualMode?.enabled} mode={integration?.mode} isFieldsAdded={isFieldsAdded} setIsFieldsAdded={setIsFieldsAdded} setShowForm={(value: boolean) => { setShowForm(value) }} setConnectionText={setConnectionText}></HubspotForm>}

        {!integration?.isConnected && <div>
            {integration?.isEnabled ? (<a href={integration.url} style={{ background: "#DD015B", padding: "9px 16px 9px 23px", color: "#FFFFFF", borderRadius: "9px", fontSize: "16px", display: "flex", justifyContent: "center", alignItems: "center" }}>Connect&nbsp;<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.25 7.75C10.25 7.33578 9.91422 7 9.5 7H9.125C6.84682 7 5 8.8468 5 11.125C5 13.4032 6.84682 15.25 9.125 15.25H12.875C15.1532 15.25 17 13.4032 17 11.125C17 9.25682 15.7581 7.67866 14.0547 7.17117C13.6323 7.04532 13.25 7.39665 13.25 7.83745V7.87622C13.25 8.24942 13.5309 8.55513 13.8759 8.69755C14.8291 9.091 15.5 10.0296 15.5 11.125C15.5 12.5747 14.3247 13.75 12.875 13.75H9.125C7.67525 13.75 6.5 12.5747 6.5 11.125C6.5 9.67525 7.67525 8.5 9.125 8.5H9.5C9.91422 8.5 10.25 8.16422 10.25 7.75Z" fill="white" />
                <path d="M8.625 3C10.9032 3 12.75 4.84682 12.75 7.125C12.75 9.4032 10.9032 11.25 8.625 11.25H8.25C7.83577 11.25 7.5 10.9142 7.5 10.5C7.5 10.0858 7.83577 9.75 8.25 9.75H8.625C10.0747 9.75 11.25 8.57475 11.25 7.125C11.25 5.67525 10.0747 4.5 8.625 4.5H4.875C3.42525 4.5 2.25 5.67525 2.25 7.125C2.25 8.22038 2.92088 9.159 3.87415 9.55245C4.21911 9.6948 4.5 10.0006 4.5 10.3738V10.4125C4.5 10.8533 4.11768 11.2047 3.69524 11.0788C1.99194 10.5713 0.75 8.99318 0.75 7.125C0.75 4.84682 2.59682 3 4.875 3H8.625Z" fill="white" />
            </svg></a>) : (<a style={{ background: "#DFDFDF", padding: "9px 16px 9px 23px", color: "#FFFFFF", borderRadius: "9px", fontSize: "16px", display: "flex", justifyContent: "center", alignItems: "center" }}>Connect&nbsp;<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.25 7.75C10.25 7.33578 9.91422 7 9.5 7H9.125C6.84682 7 5 8.8468 5 11.125C5 13.4032 6.84682 15.25 9.125 15.25H12.875C15.1532 15.25 17 13.4032 17 11.125C17 9.25682 15.7581 7.67866 14.0547 7.17117C13.6323 7.04532 13.25 7.39665 13.25 7.83745V7.87622C13.25 8.24942 13.5309 8.55513 13.8759 8.69755C14.8291 9.091 15.5 10.0296 15.5 11.125C15.5 12.5747 14.3247 13.75 12.875 13.75H9.125C7.67525 13.75 6.5 12.5747 6.5 11.125C6.5 9.67525 7.67525 8.5 9.125 8.5H9.5C9.91422 8.5 10.25 8.16422 10.25 7.75Z" fill="white" />
                <path d="M8.625 3C10.9032 3 12.75 4.84682 12.75 7.125C12.75 9.4032 10.9032 11.25 8.625 11.25H8.25C7.83577 11.25 7.5 10.9142 7.5 10.5C7.5 10.0858 7.83577 9.75 8.25 9.75H8.625C10.0747 9.75 11.25 8.57475 11.25 7.125C11.25 5.67525 10.0747 4.5 8.625 4.5H4.875C3.42525 4.5 2.25 5.67525 2.25 7.125C2.25 8.22038 2.92088 9.159 3.87415 9.55245C4.21911 9.6948 4.5 10.0006 4.5 10.3738V10.4125C4.5 10.8533 4.11768 11.2047 3.69524 11.0788C1.99194 10.5713 0.75 8.99318 0.75 7.125C0.75 4.84682 2.59682 3 4.875 3H8.625Z" fill="white" />
            </svg></a>)}
        </div>}
        {isFieldsAdded && !showForm && <Flex flexDirection={"column"} gap="10px" justifyContent={"space-around"} alignItems={"center"} width={"90%"}>
            <Flex>
                <Button onClick={() => { setShowForm(true) }} variant="secondary" borderRadius={"12px"} fontSize="14px" backgroundColor={"#fff !important"} color={"#0094E3 !important"}>
                    Edit Connection Details
                </Button>
            </Flex>
            <Flex>
                <a className="primary-anchor" onClick={() => { }} style={{ border: "1px solid #0094E3", background: "#fff", color: "#0094E3" }} href={integration.url}>
                    Refresh Connection
                </a>
            </Flex>
            <Flex>
                <Button onClick={() => { disconnectIntegration(integration.type) }} borderColor={"#E40606"} backgroundColor={"#FFF2F2 !important"} color={"#E40606"} variant="primary" borderRadius={"12px"} fontSize="14px">
                    Disconnect
                </Button>
            </Flex>

        </Flex>}
    </div>);
}