import { useEffect, useRef, useState } from "react";
import { AeReturnJourneyLayout } from "@evabot/eva-react-core-library/dist/templates/AeReturnJourneyLayout/AeReturnJourneyLayout";
import { AeSidebarSection } from "components/AeSidebar/AeSidebarSection";
import { AeSidebarSectionCollapse } from "components/AeSidebar/AeSidebarSectionCollapse";
import ProfileModalData from "containers/AEReturnDashboard/ProfileModalData";
import { useAppSelector } from "hooks";
import OtherIntegrationsRightSections from "./OtherIntegrationsRightSections";
import { useLocation, useNavigate } from "react-router-dom";
import { Backdrop } from "@mui/material";
import { axiosHelperCore } from "utils";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";


function OtherIntegrations() {
    const profileData = useAppSelector((state) => state.profileState.profileData);
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const [code, setCode] = useState(queryParams.get('code'));

    const includesGong = location.pathname.toLowerCase().includes("callbackgong");
    const isSaveToken = useRef(false);

    const saveToken = async () => {
        let refreshTokenType = "";
        if (includesGong) {
            refreshTokenType = "gong";
        }

        if (!isSaveToken.current) {
            console.log("refresh_tokenrefresh_tokenrefresh_tokenrefresh_token");
            setLoading(true);
            isSaveToken.current = true;
            const result = await axiosHelperCore({
                url: `gong/saveRefreshToken`,
                method: "POST",
                JSONData: { code: code }
            });
            navigate("/otherintegrations");           
        }
    }

    useEffect(() => {
        if (code)
            saveToken();
    }, [code]);


    return (
        <>
            {loading && <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
                open={true}>
                <Icon name="loadingIcon" />
            </Backdrop>}
            <AeReturnJourneyLayout
                as="div"
                left={20}
                right={82}
                leftChildren={<AeSidebarSection activeTab="otherintegration" />}
                leftCollapsedChildren={
                    <AeSidebarSectionCollapse activeTab="otherintegration" />
                }
                rightChildren={
                    <OtherIntegrationsRightSections />
                }
                profileModalChild={<ProfileModalData />}
                imageUrl={profileData?.imageFile}
                value={value}
                setValue={setValue}
                openSearchModal={openModal}
                setOpenSearchModal={setOpenModal}
                pageHeading="Other Integrations"
            />
        </>
    );
}

export default OtherIntegrations;
