import Button from 'components/Elements/Button';
import { useEffect, useState } from 'react';
import { axiosHelperCore } from "utils";
import { Icon } from "@evabot/eva-react-core-library/dist/atoms/Icon/Icon";
import { TextField, Backdrop } from "@mui/material";
import { useAppSelector } from 'hooks';

const OutreachForm = ({ isDualMode, isFieldAdded, setShowForm, setOutreachText, setIsFieldsAdded, mode }) => {
    const [loading, setLoading] = useState(false);
    const [IsError, setIsError] = useState(false);
    const [accountFieldData, setAccountFieldData] = useState({ accountScoreField: "", accountSummaryField: "" });
    const [prospectFieldData, setProspectFieldData] = useState({ tagField: "evabot", callPrepField: "", prospectScore: "" });


    const orgId = useAppSelector(
        (state) => state.profileState.profileData.organizationId
    );

    const getOutreachFieldData = async () => {
        setLoading(true);
        try {
            const result = await axiosHelperCore({
                url: `outreach/getData`,
                method: "POST",
                JSONData: { organizationId: orgId }
            });

            console.log("resultresultresult", result);

            if (result?.data?.outreachData) {
                const outreachData = result?.data?.outreachData;
                setAccountFieldData({ accountScoreField: outreachData?.accountScore, accountSummaryField: outreachData?.accountSummary });
                setProspectFieldData({ tagField: outreachData?.tag || "evabot", callPrepField: outreachData?.callPrep, prospectScore: outreachData?.prospectScore });
            }
        } catch (error) {
            console.error("Error fetching outreach field data:", error);
        } finally {
            setLoading(false);
        }
    };

    const saveOutreachFieldData = async () => {
        if (!validateFields()) {
            setIsError(true);
            const div = document.getElementById('scrollableDiv');
            div.scrollTop = 0;
            return;
        }

        setLoading(true);

        try {
            await axiosHelperCore({
                url: `outreach/saveData`,
                method: "POST",
                JSONData: { accountData: accountFieldData, prospectData: prospectFieldData }
            });
            setIsFieldsAdded(true);
            setShowForm(false);
            setOutreachText("Connected");
        } catch (error) {
            console.error("Error saving outreach field data:", error);
        } finally {
            setLoading(false);
        }
    };


    const validateFields = () => {
        const fields = [
            accountFieldData?.accountScoreField,
            accountFieldData?.accountSummaryField,
            prospectFieldData?.tagField,
            prospectFieldData?.callPrepField,
            prospectFieldData?.prospectScore
        ];

        const normalizedFields = fields.map(field =>
            (field !== undefined && field !== null && field.trim() !== '') ? field.trim().toLowerCase() : field
        );

        const filteredFields = normalizedFields.filter(field => field !== undefined && field !== null && field !== '');

        const uniqueFields = new Set(filteredFields);

        if (uniqueFields.size !== filteredFields.length) {
            return false;
        }

        return true;
    }

    useEffect(() => {
        if (orgId) {
            getOutreachFieldData();
        }
    }, [orgId]);

    useEffect(() => {
        setShowForm(true);
    }, []);


    const cancel = () => {
        setIsFieldsAdded(true);
        setShowForm(false);
    }

    if (isDualMode && !mode)
        return (<>  </>)

    return (
        <>
            {loading && (
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10 }}
                    open={true}
                >
                    <Icon name="loadingIcon" />
                </Backdrop>
            )}

            <div id='scrollableDiv' className="text-left" style={{ width: "90%", margin: "0 auto", maxHeight: "40vh", scrollbarWidth: "none", overflowY: "auto" }} >
                {
                    mode === "write" && (<div>
                        {IsError && <div className='text-[red] text-[12px] mb-1'>All fields must have different values.</div>}
                        <div>
                            <div>Prospect Fields</div>
                            <div style={{ padding: "10px" }}>
                                <div>
                                    <div className='text-sm text-[#57585C] mt-1 mb-1'>Call Prep</div>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        style={{ padding: "0", margin: "0", marginBottom: "10px" }}
                                        value={prospectFieldData.callPrepField || ''}
                                        onChange={(e) => setProspectFieldData({ ...prospectFieldData, callPrepField: e.target.value })}
                                        variant="outlined"
                                        inputProps={{ style: { padding: '10px', margin: 0 } }}
                                    />
                                </div>
                                <div>
                                    <div className='text-sm text-[#57585C] mt-1 mb-1'>Prospect Account Score</div>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        style={{ padding: "0", margin: "0", marginBottom: "10px" }}
                                        value={prospectFieldData.prospectScore || ''}
                                        onChange={(e) => setProspectFieldData({ ...prospectFieldData, prospectScore: e.target.value })}
                                        variant="outlined"
                                        inputProps={{ style: { padding: '10px', margin: 0 } }}
                                    />
                                </div>
                               
                            </div>

                            
                            <div >Account Fields</div>  
                            <div style={{ padding: "10px" }}>
                                    <div className='text-sm text-[#57585C] mt-1 mb-1'>Account Score</div>
                                    <TextField
                                        margin="normal"
                                        style={{ padding: "0", margin: "0", marginBottom: "10px" }}
                                        fullWidth
                                        value={accountFieldData.accountScoreField || ''}
                                        onChange={(e) => setAccountFieldData({ ...accountFieldData, accountScoreField: e.target.value })}
                                        variant="outlined"
                                        inputProps={{ style: { padding: '10px', margin: 0 } }}
                                    />
                                
                                <div>
                                    <div className='text-sm text-[#57585C]'>Account Summary</div>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        style={{ padding: "0", margin: "0", marginBottom: "10px" }}
                                        value={accountFieldData.accountSummaryField || ''}
                                        onChange={(e) => setAccountFieldData({ ...accountFieldData, accountSummaryField: e.target.value })}
                                        variant="outlined"
                                        inputProps={{ style: { padding: '10px', margin: 0 } }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>)
                }
                {
                    mode === "read" &&
                    (<div>
                        {IsError && <div className='text-[red] text-[12px] mb-1'>All fields must have different values.</div>}
                        <div>
                            <div className='mb-1'>Prospect Fields</div>
                            <div style={{ padding: "10px" }}>
                                <div>
                                    <div className='text-sm text-[#57585C] mt-1 mb-1'>Tag</div>
                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        style={{ padding: "0", margin: "0", marginBottom: "10px" }}
                                        value={prospectFieldData.tagField || ''}
                                        onChange={(e) => setProspectFieldData({ ...prospectFieldData, tagField: e.target.value })}
                                        variant="outlined"
                                        inputProps={{ style: { padding: '10px', margin: 0 } }}
                                    />
                                </div>


                            </div>
                        </div>
                    </div>)
                }
                {
                    (!mode || mode === "all") && (<div>
                        {IsError && <div className='text-[red] text-[12px] mb-1'>All fields must have different values.</div>}
                        <div>
                            <div className='mb-1'>Prospect Fields</div>
                            <div style={{ padding: "10px" }}>
                                <div>
                                    <div className='text-sm text-[#57585C] mt-1 mb-1'>Tag</div>
                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        style={{ padding: "0", margin: "0", marginBottom: "10px" }}
                                        value={prospectFieldData.tagField || ''}
                                        onChange={(e) => setProspectFieldData({ ...prospectFieldData, tagField: e.target.value })}
                                        variant="outlined"
                                        inputProps={{ style: { padding: '10px', margin: 0 } }}
                                    />
                                </div>
                                <div>
                                    <div className='text-sm text-[#57585C] mt-1 mb-1'>Call Prep</div>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        style={{ padding: "0", margin: "0", marginBottom: "10px" }}
                                        value={prospectFieldData.callPrepField || ''}
                                        onChange={(e) => setProspectFieldData({ ...prospectFieldData, callPrepField: e.target.value })}
                                        variant="outlined"
                                        inputProps={{ style: { padding: '10px', margin: 0 } }}
                                    />
                                </div>
                                <div>
                                    <div className='text-sm text-[#57585C] mt-1 mb-1'>Prospect Account Score</div>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        style={{ padding: "0", margin: "0", marginBottom: "10px" }}
                                        value={prospectFieldData.prospectScore || ''}
                                        onChange={(e) => setProspectFieldData({ ...prospectFieldData, prospectScore: e.target.value })}
                                        variant="outlined"
                                        inputProps={{ style: { padding: '10px', margin: 0 } }}
                                    />
                                </div>
                              
                            </div>
                        </div>
                        <div className="mt-3">
                            <div className='mb-1'>Account Fields</div>
                            <div style={{ padding: "10px" }}>
                      
                                <div>
                                    <div className='text-sm text-[#57585C] mt-1 mb-1'>Account Score</div>
                                    <TextField
                                        margin="normal"
                                        style={{ padding: "0", margin: "0", marginBottom: "10px" }}
                                        fullWidth
                                        value={accountFieldData.accountScoreField || ''}
                                        onChange={(e) => setAccountFieldData({ ...accountFieldData, accountScoreField: e.target.value })}
                                        variant="outlined"
                                        inputProps={{ style: { padding: '10px', margin: 0 } }}
                                    />
                                </div>
                                <div>
                                    <div className='text-sm text-[#57585C] mt-1 mb-1'>Account Summary</div>
                                    <TextField
                                        fullWidth
                                        margin="normal"
                                        style={{ padding: "0", margin: "0", marginBottom: "10px" }}
                                        value={accountFieldData.accountSummaryField || ''}
                                        onChange={(e) => setAccountFieldData({ ...accountFieldData, accountSummaryField: e.target.value })}
                                        variant="outlined"
                                        inputProps={{ style: { padding: '10px', margin: 0 } }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>)
                }

                <div className="mt-3 text-center flex flex-wrap space-x-2 justify-center">
                    <div className='mt-1'><Button variant={"salesforce"} text={isFieldAdded ? "Save" : "Save"} onClick={saveOutreachFieldData} /></div>
                    <div className='mt-1'><Button variant={"secondary"} text={"Cancel"} onClick={cancel} /></div>
                </div>
            </div>
        </>
    );
};

export default OutreachForm;
