import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SignupComponent from "containers/SignupLayout/SignupLayout";
import FieldMappingSection from "containers/FieldMapping/FieldMapping";
import EmailTemplateSetup from "containers/EmailTemplateSetup/EmailTemplateSetup";
import BudgetSetupLayoutComponent from "containers/BudgetSetup/BudgetSetupLayoutComponent";
import SlateEditor from "containers/SlateEdtitor/SlateEditor";
import ReminderTemplate from "containers/ReminderTemplate/ReminderTemplate";
import InviteUsersLayoutComponent from "containers/InviteUsers/InviteUsersLayoutComponent";
import { AEOverviewLayout } from "containers/AEOverviewLayout/AEOverviewLayout";
import OpportunitySelectionLayout from "containers/OpportunitySelectionComponent/OpportunitySelectionComponent";
import StakeHolder from "containers/StakeHolder/StakeHolder";
import {
  AEOverviewContent1,
  AEOverviewContent2,
} from "./../utils/AEOverviewContent";
import AESignupLayoutO1 from "containers/AESignupLayout/AESignupLayoutO1";
import AESignupLayoutO2 from "containers/AESignupLayout/AESignupLayoutO2";
import AEListOpportunityOption2 from "containers/AEListOfOpportunityOption2/AEListOpportunityOption2";
import AEListOpportunityOption1 from "containers/AEListOfOpportunityOption1/AEListOfOpportunityOption1";
import SendOpportunityOption1 from "containers/SendOpportunityOption1/SendOpportunityOption1";
import AEReturnDashboard from "containers/AEReturnDashboard/AEReturnDashboard";
import AEReturnStakeholderDashboard from "containers/AeReturnStakeholderDashboard/AeReturnStakeholderDashboard";
import { NudgeFeedLayout } from "containers/NudgeFeed/NudgeFeed";
import { AeReturnUnlikeModalContainer } from "components/AeReturnUnlikeModal/AeReturnUnlikeModalContainer";
import AEReturnProfile from "containers/AEReturnProfile/AEReturnProfile";
import EmailEditor from "containers/EmailEditor/EmailEditor";
import AeFormTemplate from "containers/AeForgotPassword/AeFormTemplate";
import AeForgotPasswordLeftSection from "containers/AeForgotPassword/AeForgotPasswordLeftSection";
import AeForgotPasswordRightSection from "containers/AeForgotPassword/AeForgotPasswordRightSection";
import AeChangePasswordLeftSection from "containers/AeForgotPassword/AeChangePasswordLeftSection";
import AeChangePasswordRightSection from "containers/AeForgotPassword/AeChangePasswordRightSection";
import AeEmailTemplate from "containers/AeForgotPassword/AeEmailTemplate";
import AdminDashboard from "containers/AdminDashboard/AdminDashboard";
import RedirectScreen from "containers/RedirectScreen/RedirectScreen";
import AeReturnOpportunities from "containers/AeReturnOpportunities/AeReturnOpportunities";
import AeReturnStakeholders from "containers/AeReturnStakeholders/AeReturnStakeholders";
import NameFilterView from "containers/AEReturnDashboard/NameFilterView";
import AeReturnActivityHistory from "containers/AeReturnActivityHistory/AeReturnActivityHistory";
import AeReturnAnalytics from "containers/AeReturnAnalytics/AeReturnAnalytics";
import AeReturnIntegration from "containers/AeReturnIntegration/AeReturnIntegration";
import StakeholderTag from "containers/StakeholderTag/StakeholderTag";
import { useAppSelector } from "hooks";
import RouteAccess from "../middleware/RouteAccess";
import ProspectReport from "containers/ProspectReport/ProspectReport";
import NotFoundPage from "components/NotFoundPage/NotFoundPage";
import MemberListing from "containers/MemberListing/MemberListing";
import ManualProcess from "containers/ProspectProcess/ProspectProcess";
import SettingMenu from "./SettingMenu";
import FrameworkAndContext from "containers/FrameworkAndContext/FrameworkAndContext";
import TeamManagement from "containers/TeamManagement/TeamManagement";
import EmailTemplate from "containers/EmailTemplate/EmailTemplate";
import AddTemplateForm from "containers/EmailTemplate/component/AddTemplateForm";
import CompanyScoreReport from "containers/CompanyScoreReport/CompanyScoreReport";
import AutomatedEmailSequence from "containers/AutomatedEmailSequence/AutomatedEmailSequence";
import AESsoLogin from "containers/AESignupLayout/AESsoLogin";
import AccountReport from "containers/AccountReport/AccountReport";
import AdminReport from "containers/AdminReport/AdminReport";
import ApolloSearch from "containers/ApolloSearch/ApolloSearch";
import OtherIntegrations from "containers/OtherIntegrations/OtherIntegrations";
import ManagePersonas from "containers/ManagePersonas/ManagePersonas";

const EvaRoutes = () => {

  const userData = useAppSelector(
    (state) => state.profileState?.profileData
  );

  const userMenuAccess = useAppSelector((state) => state.globalState.menuItems);

  return (
    <Router>
      <Routes>
        {/* ADMIN Option-1 Journey Routes */}
        <Route path="/" element={<RedirectScreen userMenuAccess={userMenuAccess} />} />
        <Route path="/callbackGoogle" element={<RedirectScreen userMenuAccess={userMenuAccess} />} />
        <Route path="/admin1" element={<SignupComponent />} />
        <Route path="/admin2" element={<SignupComponent />} />
        <Route path="/fieldMappingSection" element={<FieldMappingSection />} />
        <Route
          path="/fieldMappingSection/:previous"
          element={<FieldMappingSection />}
        />
        <Route
          path="/opportunityLayout"
          element={<OpportunitySelectionLayout />}
        />
        <Route
          path="/budgetSetupLayout"
          element={<BudgetSetupLayoutComponent />}
        />
        <Route path="/emailTemplateSetup" element={<EmailTemplateSetup />} />
        <Route
          path="/emailTemplateSetup/:badge"
          element={<EmailTemplateSetup />}
        />
        <Route path="/emaileditor/:type" element={<EmailEditor />} />
        <Route
          path="/inviteUsersLayout"
          element={<InviteUsersLayoutComponent />}
        />
        <Route
          path="/inviteUsersLayout/:email"
          element={<InviteUsersLayoutComponent />}
        />
        <Route path="/slateEditor" element={<SlateEditor />} />
        <Route path="/slateEditor/:navigateUrl" element={<SlateEditor />} />
        <Route path="/reminderTemplate" element={<ReminderTemplate />} />
        <Route path="/reminderTemplate/:email" element={<ReminderTemplate />} />
        <Route
          path="/emailTemplateEditorSetup"
          element={<BudgetSetupLayoutComponent />}
        />

        {/* ADMIN Option-2 Journey Routes */}
        <Route path="/stakeHolder" element={<StakeHolder />} />

        {/* ADMIN Return Journey */}
        <Route path="/adminDashboard" element={<AdminDashboard />} />

        {/* AE Option-1 Journey Routes */}
        <Route path="/aeLogin1" element={<AESignupLayoutO1 />} />
        <Route path="/workos/callbackWorkOS" element={<AESsoLogin />} />
        <Route
          path="/overviewLayout1"
          element={
            <AEOverviewLayout data={AEOverviewContent1} optionType={1} />
          }
        />
        <Route
          path="/aeListOpportunity1"
          element={<AEListOpportunityOption1 />}
        />
        <Route path="/sendOpportunity1" element={<SendOpportunityOption1 />} />

        {/* AE Option-2 Journey Routes */}
        <Route path="/aeLogin2" element={<AESignupLayoutO2 />} />
        <Route
          path="/overviewLayout2"
          element={
            <AEOverviewLayout data={AEOverviewContent2} optionType={2} />
          }
        />
        <Route
          path="/aeListOpportunity2"
          element={<AEListOpportunityOption2 />}
        />

        {/* AE Option 1 Return Journey */}
        <Route
          path="/aeReturnDashboard/stakeholder/:stakeholderId"
          element={<AEReturnStakeholderDashboard />}
        />


        <Route path="/aeReturnProfile" element={<AEReturnProfile />} />
        <Route
          path="/aeReturnOpportunities"
          element={<AeReturnOpportunities />}
        />
        <Route
          path="/aeReturnStakeholders"
          element={<RouteAccess userMenuAccess={userMenuAccess} name="stakeholders"><AeReturnStakeholders /></RouteAccess>}
        />
        <Route
          path="/aeReturnActivityHistory"
          element={<RouteAccess userMenuAccess={userMenuAccess} name="history"><AeReturnActivityHistory /></RouteAccess>}
        />
        <Route
          path="/aeReturnActivityHistory/:id"
          element={<AeReturnActivityHistory />}
        />

        <Route
          path="aeUnlikeModal"
          element={<AeReturnUnlikeModalContainer />}
        />
        <Route path="/nudges" element={<NudgeFeedLayout />} />

        {/* AE Journey Forgot Password */}
        <Route
          path="/forgotPassword"
          element={
            <AeFormTemplate
              leftChild={<AeForgotPasswordLeftSection />}
              rightChild={<AeForgotPasswordRightSection />}
              formType="forgot"
            />
          }
        />
        <Route
          path="/resetPassword"
          element={
            <AeFormTemplate
              leftChild={<AeForgotPasswordLeftSection />}
              rightChild={<AeForgotPasswordRightSection type={"reset"} />}
              formType="forgot"
            />
          }
        />

        <Route
          path="/changeEvaPassword/:userHash"
          element={
            <AeFormTemplate
              leftChild={<AeChangePasswordLeftSection />}
              rightChild={<AeChangePasswordRightSection />}
              formType="change"
            />
          }
        />

        <Route path="/checkEmail" element={<AeEmailTemplate />} />

        {/* SDR Journey Password */}

        <Route path="/analytics" element={<RouteAccess userMenuAccess={userMenuAccess} name="analytics"><AeReturnAnalytics /></RouteAccess>} />
        <Route path="/integrations" element={<RouteAccess userMenuAccess={userMenuAccess} name="integration"><AeReturnIntegration /></RouteAccess>} />
        <Route path="/callbackSalesloft" element={<AeReturnIntegration />} />
        <Route path="/callbackOutreach" element={<AeReturnIntegration />} />
        <Route path="/callbackHubSpot" element={<AeReturnIntegration />} />
        <Route path="/callbackGong" element={<OtherIntegrations />} />
        <Route path="/callbackSalesforce" element={<AeReturnIntegration />} />
        <Route path="/prospectReport" element={<RouteAccess userMenuAccess={userMenuAccess} name="prospectreport"><ProspectReport /></RouteAccess>} />
        <Route path="/companyScoreReport" element={<RouteAccess userMenuAccess={userMenuAccess} name="companyscorereport"><CompanyScoreReport /></RouteAccess>} />
        <Route path="/memberlisting" element={<RouteAccess userMenuAccess={userMenuAccess} name="memberlisting"><MemberListing /></RouteAccess>} />
        <Route path="/prospectprocess" element={<RouteAccess userMenuAccess={userMenuAccess} name="prospectprocess"><ManualProcess /></RouteAccess>} />
        <Route path="/stakeholdertag" element={<RouteAccess userMenuAccess={userMenuAccess} name="tag"><StakeholderTag /></RouteAccess>} />
        <Route path="/frameworkandcontext" element={<RouteAccess userMenuAccess={userMenuAccess} name="frameworkandcontext"><FrameworkAndContext /></RouteAccess>} />
        <Route path="/teammanagement" element={<RouteAccess userMenuAccess={userMenuAccess} name="teammanagement"><TeamManagement /></RouteAccess>} />
        <Route path="/emailtemplates" element={<EmailTemplate />} />
        <Route path="/nudgesAI" element={<RouteAccess userMenuAccess={userMenuAccess} name="nudges"><AEReturnDashboard /></RouteAccess>} />
        <Route path="/automatedemail" element={<RouteAccess userMenuAccess={userMenuAccess} name="automatedemail"> <AutomatedEmailSequence /></RouteAccess>} />
        <Route path="/otherintegrations" element={<RouteAccess userMenuAccess={userMenuAccess} name="otherintegrations"> <OtherIntegrations></OtherIntegrations>  </RouteAccess>} />
        <Route path="/accountreport" element={<RouteAccess userMenuAccess={userMenuAccess} name="accountreport"> <AccountReport /></RouteAccess>} />
        <Route path="/adminreport" element={<RouteAccess userMenuAccess={userMenuAccess} name="adminreport"> <AdminReport /></RouteAccess>} />
        <Route path="/managepersonas" element={<RouteAccess userMenuAccess={userMenuAccess} name="managepersonas"> <ManagePersonas /></RouteAccess>} />
        <Route path="/apollosearch" element={<RouteAccess userMenuAccess={userMenuAccess} name="apollosearch"> <ApolloSearch /></RouteAccess>} />
        <Route path='*' element={<NotFoundPage />} />

      </Routes>
    </Router>
  );
};

export default EvaRoutes;
