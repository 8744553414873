import React, { useState, useEffect } from 'react';
import { TextField, InputAdornment, MenuItem, Select, FormControl, InputLabel, Autocomplete, Tooltip, IconButton } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { ToggleButton } from '@evabot/eva-react-core-library/dist/atoms/ToggleButton/ToggleButton';
import { ModalWrapper } from "@evabot/eva-react-core-library/dist/molecules/ModalWrapper/ModalWrapper";
import { Link } from 'react-router-dom';

const StepForm = ({ isSubmitted, errorMessage, stepId, orderBy, sequenceId, stepName, stepType, defaultEmail, defaultLinkedin, frameworkList, toneList, wordCountList, contextList, onChange, dropDownList, usedEmailOptions, globalUsedCustomValues, setGlobalUsedCustomValues, defaultType = "", isDisabled }) => {
  const selectedClass = "bg-[#0094E3] px-3 py-2 border rounded-[30px] border-gray-200 text-[#fff] text-[13px]";
  const notSelectedClass = "bg-[white] px-3 py-2 border rounded-[30px] border-gray-200 text-[#000] text-[13px]";

  const inputDisabled: React.CSSProperties = !isDisabled
    ? {}
    : {
      pointerEvents: 'none' as 'none',
      opacity: 0.6,
    };

  const getDefaultFramework = () => {
    if (defaultType.includes('email') || defaultType === 'linkedinInMail') {
      return defaultEmail?.frameworkId;
    }

    if (defaultType.includes('call')) {
      return frameworkList.filter(fw => fw.type === "callprep").find(fw => fw.name === "DEFAULT")?.frameworkId;
    }
    return '';
  };

  const getDefaultTone = () => {
    if (defaultType.includes('email') && !defaultEmail?.frameworkId) {
      const matchedTone = toneList.find(tone => tone?.value === defaultEmail?.tone);
      return matchedTone?.toneId || '';
    }

    if (defaultType === 'linkedinConnectionRequest') {
      const matchedTone = toneList.find(tone => tone?.value === defaultLinkedin?.linkedinConnectionRequest?.tone);
      return matchedTone?.toneId || '';
    }

    if (defaultType === 'linkedinDirectMessage') {
      const matchedTone = toneList.find(tone => tone?.value === defaultLinkedin?.linkedinDirectMessage?.tone);
      return matchedTone?.toneId || '';
    }

    if (defaultType === 'linkedinInMail') {
      const matchedTone = toneList.find(tone => tone?.value === defaultLinkedin?.linkedinDirectMessage?.tone);
      return matchedTone?.toneId || '';
    }

    return '';
  };

  const getDefaultWordLimit = () => {
    if (defaultType.includes('email')) {
      const matchedWordLimit = wordCountList.find(wordLimit => wordLimit?.value === defaultEmail?.wordLimit);
      return matchedWordLimit?.wordId || '';
    }

    if (defaultType === 'linkedinConnectionRequest') {
      const matchedWordLimit = wordCountList.find(wordLimit => wordLimit?.value === defaultLinkedin?.linkedinConnectionRequest?.wordLimit);
      return matchedWordLimit?.wordId || '';
    }

    if (defaultType === 'linkedinDirectMessage') {
      const matchedWordLimit = wordCountList.find(wordLimit => wordLimit?.value === defaultLinkedin?.linkedinDirectMessage?.wordLimit);
      return matchedWordLimit?.wordId || '';
    }

    if (defaultType === 'linkedinInMail') {
      const matchedWordLimit = wordCountList.find(wordLimit => wordLimit?.value === defaultLinkedin?.linkedinDirectMessage?.wordLimit);
      return matchedWordLimit?.wordId || '';
    }

    return '';
  };

  //   const modifiedToneList = [{ toneId: '', name: 'None' }, ...toneList];

  //   const defaultWordLimit = stepType.includes('email') ? '6081f0b5-0ffc-4570-ba6a-8b2f6f27ab74' : '7102f6dc-2f2b-4798-ace3-5294c21946c5';  

  const [isDeleteConfirmationShow, setIsDeleteConfirmationShow] = useState(false);
  const [isActivated, setIsActivated] = useState(!isDisabled);
  const [usedOptions, setUsedOptions] = useState([...usedEmailOptions]);
  const [formData, setFormData] = useState({
    emailSubject: '',
    emailBody: '',
    linkedinSubject: '',
    linkedinBody: '',
    callprepBody: '',
    selectedFramework: getDefaultFramework(),
    selectedTone: getDefaultTone(),
    selectedWordLimit: getDefaultWordLimit(),
    selectedContext: '',
    type: defaultType,
    isActive: !isDisabled,
  });

  const onDeleteSetting = async () => {
    setIsDeleteConfirmationShow(false);
    const newActivationStatus = !formData.isActive;
    setFormData({ ...formData, isActive: newActivationStatus });
    setIsActivated(newActivationStatus);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    const oldValue = formData[name];

    setGlobalUsedCustomValues(prevValues => {
      const filteredValues = prevValues.filter(val => val !== oldValue);
      return [...filteredValues, value].filter(val => val);
    });

    setFormData({ ...formData, [name]: value });
  };

  const handleSelect = (type, value) => {
    let updatedFormData = {
      ...formData,
      [type]: value,
    };

    if (type === 'selectedFramework' && !value) {
      const matchedTone = toneList.find(tone => tone?.value === defaultEmail?.tone);
      updatedFormData = {
        ...updatedFormData,
        selectedTone: matchedTone?.toneId,
      }
    }

    if (type === 'selectedFramework' && value) {
      updatedFormData = {
        ...updatedFormData,
        selectedTone: '',
      }
    }

    // Automatically select tone & word limit for LinkedIn types
    if (type === 'type') {
      if (value === 'linkedinDirectMessage') {
        updatedFormData = {
          ...updatedFormData,
          selectedFramework: '',
          selectedTone: toneList.find(tone => tone?.value === defaultLinkedin?.linkedinDirectMessage?.tone)?.toneId || '',
          selectedWordLimit: wordCountList.find(wordLimit => wordLimit?.value === defaultLinkedin?.linkedinDirectMessage?.wordLimit)?.wordId || '',
        };
      } else if (value === 'linkedinConnectionRequest') {
        updatedFormData = {
          ...updatedFormData,
          selectedFramework: '',
          selectedTone: toneList.find(tone => tone?.value === defaultLinkedin?.linkedinConnectionRequest?.tone)?.toneId || '',
          selectedWordLimit: wordCountList.find(wordLimit => wordLimit?.value === defaultLinkedin?.linkedinConnectionRequest?.wordLimit)?.wordId || '',
        };
      }
      // else if (value === 'linkedinInMail') {
      //   updatedFormData = {
      //     ...updatedFormData,
      //     // selectedFramework: '',
      //     selectedTone: toneList.find(tone => tone?.value === defaultLinkedin?.linkedinDirectMessage?.tone)?.toneId || '',
      //     selectedWordLimit: wordCountList.find(wordLimit => wordLimit?.value === defaultLinkedin?.linkedinDirectMessage?.wordLimit)?.wordId || '',
      //   };
      // }
    }

    setFormData(updatedFormData);
  };

  useEffect(() => {
    onChange(stepId, sequenceId, {
      ...formData,
      stepName,
      stepType,
      crmStepId: stepId,
      isActive: isActivated,
      orderBy: orderBy
    });
  }, [formData]);

  const sectionDisabled: React.CSSProperties = isActivated
    ? {}
    : {
      pointerEvents: 'none' as 'none',
      opacity: 0.6,
    };

  //   useEffect(() => {
  //     if (formData.type.includes('email')) {
  //       usedEmailOptions.push(formData.type);
  //     }
  //   }, [formData.type]);


  const getStepType = (type) => {
    if (type.startsWith("followUp")) {
      const [baseType, ...rest] = type.split('-');
      const StepBaseType = "Follow Up";

      const StepRest = rest.join(' ');

      return `${StepBaseType} ${StepRest}`.trim();
    }

    return null;
  };

  const StepType = getStepType(formData.type);
  let combinedStepName = stepName;

  if (StepType) {
    combinedStepName = stepName.replace(/\)$/, ` : ${StepType})`);
  }

  return (
    <div id={"id_" + stepId} className="step-form mb-6 border bg-white shadow-lg rounded-lg p-6" style={inputDisabled}>
      {errorMessage && isSubmitted && errorMessage.split("$$$")[0] === "id_" + stepId && <div className="text-red-500 text-[12px]">{errorMessage.split("$$$")[1]}</div>}
      <div className='flex justify-between'>
        <div className="text-xl text-[#050505] font-medium">{combinedStepName}</div>
        <Tooltip title={"Disabling this step will remove it from the sequence and prevent output generation"} placement="top-start" disableHoverListener={!isActivated} arrow>
          <div>
            <ToggleButton
              value={isActivated}
              name='isActive'
              variant="tertiary"
              onChange={(value) => { setIsDeleteConfirmationShow(true) }}
            />
          </div>
        </Tooltip>
      </div>
      {!isDisabled && <div className='mt-5'>
        <FormControl margin="normal" style={{ width: "150px" }} disabled={!isActivated}>
          <InputLabel id="select-type-label">Select Type</InputLabel>
          <Select
            labelId="select-type-label"
            label="Select Type"
            value={formData.type}
            style={{ width: "200px" }}
            onChange={(e) => handleSelect('type', e.target.value)}
          >
            {dropDownList?.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div style={sectionDisabled}>
          <div className="flex items-start input-fields col my-1" style={{ alignItems: 'flex-start', gap: '1rem' }}>
            {formData?.type?.includes('email') && (
              <>
                <div className='w-1/2'>
                  <TextField
                    label="Email Subject Custom Field"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="emailSubject"
                    value={formData.emailSubject}
                    onChange={handleChange}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Custom field name should be unique.">
                            <IconButton>
                              <InfoIcon color="primary" />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {!formData.emailSubject?.trim() && isSubmitted && formData?.isActive && <div className="text-red-500 text-[12px]">subject is required</div>}
                </div>
                <div className='w-1/2'>
                  <TextField
                    label="Email Body Custom Field"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="emailBody"
                    value={formData.emailBody}
                    onChange={handleChange}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Custom field name should be unique.">
                            <IconButton>
                              <InfoIcon color="primary" />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {!formData.emailBody?.trim() && isSubmitted && formData?.isActive && <div className="text-red-500 text-[12px]">email body is required</div>}
                </div>
              </>
            )}
            {formData?.type?.includes('linkedinInMail') && (
              <>
                <div className='w-1/2'>
                  <TextField
                    label="Linkedin Subject Custom Field"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="linkedinSubject"
                    value={formData.linkedinSubject}
                    onChange={handleChange}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Custom field name should be unique.">
                            <IconButton>
                              <InfoIcon color="primary" />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {!formData.linkedinSubject?.trim() && isSubmitted && formData?.isActive && <div className="text-red-500 text-[12px]">linkedin subject is required</div>}
                </div>
                <div className='w-1/2'>

                  <TextField
                    label="Linkedin Body Custom Field"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="linkedinBody"
                    value={formData.linkedinBody}
                    onChange={handleChange}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Custom field name should be unique.">
                            <IconButton>
                              <InfoIcon color="primary" />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {!formData.linkedinBody?.trim() && isSubmitted && formData?.isActive && <div className="text-red-500 text-[12px]">linkedin body is required</div>}
                </div>

              </>
            )}
            {(formData?.type.includes('linkedinDirectMessage') || formData?.type.includes('linkedinConnectionRequest')) && (
              <div style={{ width: "100%" }}>
                <TextField
                  label={'LinkedIn Custom Field'}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="linkedinBody"
                  value={formData.linkedinBody}
                  onChange={handleChange}
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Custom field name should be unique.">
                          <IconButton>
                            <InfoIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
                {!formData.linkedinBody?.trim() && isSubmitted && formData?.isActive && <div className="text-red-500 text-[12px]">linkedin body is required</div>}
              </div>
            )}
            {formData?.type.includes('followUp') && (
              <>
                <div style={{ width: "100%" }}>
                  <TextField
                    label="Follow Up Email Body Custom Field"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="emailBody"
                    value={formData.emailBody}
                    onChange={handleChange}
                    required
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Custom field name should be unique.">
                            <IconButton>
                              <InfoIcon color="primary" />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {!formData.emailBody?.trim() && isSubmitted && formData?.isActive && <div className="text-red-500 text-[12px]">follow up email body is required</div>}
                </div>
              </>
            )}
            {(formData?.type.includes('call')) && (
              <div style={{ width: "100%" }}>
                <TextField
                  label={'Call Custom Field'}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  name="callprepBody"
                  value={formData.callprepBody}
                  onChange={handleChange}
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Custom field name should be unique.">
                          <IconButton>
                            <InfoIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
                {!formData.callprepBody?.trim() && isSubmitted && formData?.isActive && <div className="text-red-500 text-[12px]">call field is required</div>}
              </div>
            )}
          </div>
        </div>
        {<div className="dropdowns my-4" style={sectionDisabled}>
          {(defaultType?.includes('email') || defaultType?.includes('followUp') || defaultType === 'linkedinInMail' || formData?.type.includes('linkedinInMail') || formData?.type.includes('call')) && <div className="py-2">
            {/* <div className="py-1 text-[16px]">Select framework</div> */}
            <div className="flex gap-4 cursor-pointer flex-wrap">
              {/* {frameworkList.map((framework) => (
              <div
                key={framework.frameworkId}
                className={formData.selectedFramework === framework.frameworkId ? selectedClass : notSelectedClass}
                onClick={() => handleSelect('selectedFramework', framework.frameworkId)}
              >
                {framework.name}
              </div>
            ))} */}

              <Autocomplete
                options={formData?.type.includes('call') ? frameworkList.filter(fw => (fw.type === "callprep") && (fw?.isPublic === undefined || fw?.isPublic)) : frameworkList.filter(fw => (fw.type === "email" || fw.frameworkId == "") && (fw?.isPublic === undefined || fw?.isPublic))}
                getOptionLabel={(option) => option.name}
                // renderOption={(props, option) => {
                //   const isPublic = option?.isPublic === undefined || option?.isPublic === true;
                //   return (
                //     <li {...props}>
                //       {option.name}{" "}
                //       <span style={{ color: isPublic ? "green" : "red", marginLeft: "8px" }}>
                //         {isPublic ? "(Public)" : "(Private)"}
                //       </span>
                //     </li>
                //   );
                // }}
                renderInput={(params) => <TextField {...params} label="Select framework (public only)" variant="outlined" />}
                value={frameworkList.find(fw => fw.frameworkId === formData?.selectedFramework && fw.frameworkId != "") || (formData?.type.includes('call') ? frameworkList.find(fw => fw.name === "DEFAULT") : frameworkList.find(fw => fw.frameworkId === ""))}
                onChange={(event, newValue) => {
                  if (formData?.type.includes('call'))
                    handleSelect('selectedFramework', newValue ? newValue.frameworkId : frameworkList.find(fw => fw.name === "DEFAULT")?.frameworkId);
                  else
                    handleSelect('selectedFramework', newValue ? newValue.frameworkId : '');
                }}
                style={{ width: "50%" }}
              />
            </div>
            <div className='font-normal' style={{ fontSize: "12px", color: "#57585C", display: 'flex', alignItems: 'center', gap: '5px', marginTop: "10px" }}>
              Want to add a custom framework?<Link to="/frameworkandcontext" style={{ color: "#0094E3", textDecoration: "underline" }}>click here</Link>
            </div>
            {!formData?.type.includes('call') && formData?.selectedFramework && frameworkList.find(fw => fw.frameworkId === formData?.selectedFramework) && <div style={{ fontSize: "10px", backgroundColor: "#DCF9FF", color: "#57585C", display: 'flex', alignItems: 'center', gap: '10px', marginTop: "10px", border: "1px solid #0094E3", padding: '5px', borderRadius: '5px' }}>
              {(defaultType.includes('email') || defaultType?.includes('followUp') || defaultType === 'linkedinInMail' || formData?.type.includes('linkedinInMail') || formData?.type.includes('call')) && frameworkList.find(fw => fw.frameworkId === formData.selectedFramework)?.framework}
            </div>}

            {formData?.type.includes('call') && frameworkList.find(fw => fw.frameworkId === formData?.selectedFramework) && <div style={{ fontSize: "10px", backgroundColor: "#DCF9FF", color: "#57585C", display: 'flex', alignItems: 'center', gap: '10px', marginTop: "10px", border: "1px solid #0094E3", padding: '5px', borderRadius: '5px' }}>
              {frameworkList.find(fw => fw.frameworkId === formData.selectedFramework)?.framework || frameworkList.find(fw => fw.name === "DEFAULT")?.framework}
            </div>}
          </div>}

          {!formData?.selectedFramework && !formData?.type.includes('followUp') && !formData?.type.includes('call') && <div className="py-2">
            <div className="py-2 text-[16px]">Select email tone</div>
            <div className="flex gap-2 cursor-pointer">
              {toneList?.map((tone) => (
                <div
                  key={tone.toneId}
                  className={formData.selectedTone === tone.toneId ? selectedClass : notSelectedClass}
                  onClick={() => handleSelect('selectedTone', tone.toneId)}
                >
                  {tone.name}
                </div>
              ))}
            </div>
          </div>}

          {!(defaultType === 'linkedinConnectionRequest' || formData.type === 'linkedinConnectionRequest') && !formData?.type.includes('followUp') && !formData?.type.includes('call') && <div className="py-2">
            <div className="py-2 text-[16px]">Select email word limit <span style={{ fontSize: "12px", color: "#57585C" }}>(120 recommended limit)</span></div>
            <div className="flex gap-2 cursor-pointer">
              {wordCountList.map((wordCount) => (
                <div
                  key={wordCount.wordId}
                  className={formData.selectedWordLimit === wordCount.wordId ? selectedClass : notSelectedClass}
                  onClick={() => handleSelect('selectedWordLimit', wordCount.wordId)}
                >
                  {wordCount.name}
                </div>
              ))}
            </div>
          </div>}
        </div>}
        {(formData.type.includes('followUp') || formData.type.includes('email')) && <Autocomplete
          options={contextList.filter(item => (item.type === 'email' || item.contextId === "") && (item?.isPublic === undefined || item?.isPublic))}
          getOptionLabel={(option) => option.name}
          // renderOption={(props, option) => {
          //     const isPublic = option?.isPublic === undefined || option?.isPublic === true;
          //     return (
          //         <li {...props}>
          //         {option.name}{" "}
          //         <span style={{ color: isPublic ? "green" : "red", marginLeft: "8px" }}>
          //             {isPublic ? "(Public)" : "(Private)"}
          //         </span>
          //         </li>
          //     );
          // }}
          renderInput={(params) => <TextField {...params} label="Select Context (public only)" variant="outlined" />}
          value={contextList.filter(item => item.type === 'email' || item.contextId === "")?.find(fw => fw.contextId === formData.selectedContext) || null}
          onChange={(e, newValue) => {
            handleSelect('selectedContext', newValue ? newValue.contextId : '');
          }}
          style={{ width: "50%", marginTop: '20px' }}
        />}

        {formData.type.includes('linkedin') && <Autocomplete
          options={contextList.filter(item => (item.type === 'linkedin' || item.contextId === "") && (item?.isPublic === undefined || item?.isPublic))}
          getOptionLabel={(option) => option.name}
          // renderOption={(props, option) => {
          //     const isPublic = option?.isPublic === undefined || option?.isPublic === true;
          // return (
          //     <li {...props}>
          //     {option.name}{" "}
          //     <span style={{ color: isPublic ? "green" : "red", marginLeft: "8px" }}>
          //         {isPublic ? "(Public)" : "(Private)"}
          //     </span>
          //     </li>
          // );
          //}}
          renderInput={(params) => <TextField {...params} label="Select Context (public only)" variant="outlined" />}
          value={contextList.filter(item => item.type === 'linkedin' || item.contextId === "")?.find(fw => fw.contextId === formData.selectedContext) || null}
          onChange={(e, newValue) => {
            handleSelect('selectedContext', newValue ? newValue.contextId : '');
          }}
          style={{ width: "50%", marginTop: '20px' }}
        />}
        {formData.type.includes('call') && <Autocomplete
          options={contextList.filter(item => (item.type === 'callprep' || item.contextId === "") && (item?.isPublic === undefined || item?.isPublic))}
          getOptionLabel={(option) => option.name}
          // renderOption={(props, option) => {
          //     const isPublic = option?.isPublic === undefined || option?.isPublic === true;
          //     return (
          //         <li {...props}>
          //         {option.name}{" "}
          //         <span style={{ color: isPublic ? "green" : "red", marginLeft: "8px" }}>
          //             {isPublic ? "(Public)" : "(Private)"}
          //         </span>
          //         </li>
          //     );
          // }}
          renderInput={(params) => <TextField {...params} label="Select Context (public only)" variant="outlined" />}
          value={contextList.filter(item => item.type === 'callprep' || item.contextId === "")?.find(fw => fw.contextId === formData.selectedContext) || null}
          onChange={(e, newValue) => {
            handleSelect('selectedContext', newValue ? newValue.contextId : '');
          }}
          style={{ width: "50%", marginTop: '20px' }}
        />}
        {contextList.find(cx => cx.contextId === formData?.selectedContext)?.context && <div style={{ fontSize: "10px", backgroundColor: "#DCF9FF", color: "#57585C", display: 'flex', alignItems: 'center', gap: '10px', marginTop: "10px", border: "1px solid #0094E3", padding: '5px', borderRadius: '5px' }}>
          {contextList.find(cx => cx.contextId === formData.selectedContext)?.context}
        </div>}
      </div>}

      <ModalWrapper
        width="max-content"
        height="max-content"
        hideModal={!isDeleteConfirmationShow}
        setHideModal={() => {
          setIsDeleteConfirmationShow(false);
        }}
        bgcolor={"rgba(0, 0, 0, 0.8)"}
        padding="40px"
      >
        <Flex
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Text fontSize={"20px"} fontWeight={300}>
            {isActivated ? "Are you sure you want to deactivate this Setting" : "Are you sure you want to activate this Setting"}
          </Text>
          <Flex mt="15px">
            <Flex
              background={"#DD015B"}
              color={"#fff"}
              fontSize={"16px"}
              fontWeight={500}
              p="10px 32px"
              justifyContent={"center"}
              alignItems={"center"}
              borderRadius={"12px"}
              width={"max-content"}
              cursor="pointer"
              onClick={() => { onDeleteSetting() }}
            >
              Yes
            </Flex>
            <Flex
              ml="16px"
              border="1px solid #0094E3"
              background={"#fff"}
              color={"#0094E3"}
              fontSize={"16px"}
              fontWeight={500}
              p="10px 32px"
              justifyContent={"center"}
              alignItems={"center"}
              borderRadius={"12px"}
              width={"max-content"}
              cursor="pointer"
              onClick={() => { setIsDeleteConfirmationShow(false) }}
            >
              Cancel
            </Flex>
          </Flex>
        </Flex>
      </ModalWrapper>
    </div>
  );
};

export default StepForm;
