import React, { useState, useEffect } from "react";
import { Form, Formik, ErrorMessage } from 'formik';
import { Text } from "@evabot/eva-react-core-library/dist/atoms/Text/Text";
import { FormikInput } from "@evabot/eva-react-core-library/dist/atoms/FormikInput/FormikInput";
import { Box } from "@evabot/eva-react-core-library/dist/atoms/Box/Box";
import { Flex } from "@evabot/eva-react-core-library/dist/atoms/Flex/Flex";
import { Button } from "@evabot/eva-react-core-library/dist/atoms/Button/Button";
import Select from 'react-select';
import * as yup from 'yup';



const MenuValue = [
    { value: "analytics", label: "Home" },
    { value: "integration", label: "Integrations" },
    { value: "stakeholders", label: "Stakeholders" },
    { value: "teammanagement", label: "Team Management" },
    { value: "frameworkandcontext", label: "Frameworks / Contexts" },
    { value: "prospectprocess", label: "Excel Upload" },
    { value: "memberlisting", label: "Member Listing" },
    { value: "prospectreport", label: "Prospects" },
    { value: "companyscorereport", label: "Accounts" },
    { value: "emailtemplates", label: "Email Templates" },
    { value: "automatedemail", label: "Automated Sequence" },
    { value: "otherintegrations", label: "Other Integrations" },
];

const adminMenu = [
    { value: "analytics", label: "Home" },
    { value: "prospectreport", label: "Prospects" },
    { value: "companyscorereport", label: "Accounts" },
    { value: "teammanagement", label: "Team Management" },
    { value: "frameworkandcontext", label: "Frameworks / Contexts" },
    { value: "integration", label: "Integrations" },
];

const sdrAeMenu = [
    { value: "analytics", label: "Home" },
    { value: "prospectreport", label: "Prospects" },
    { value: "companyscorereport", label: "Accounts" },
];

const usertypeOptions = [
    { value: "admin", label: "Admin" },
    { value: "sdr", label: "SDR" },
    { value: "ae", label: "AE" }
];

const addmembertypeOptions = [
    { value: "owner", label: "Owner" },
    { value: "child", label: "Child" },
];

const AddMemberForm = ({ onSubmit, handleClose, adminMemberList, tabView }) => {
    const [selectedCompanyId, setSelectedCompanyId] = useState("");
    const [selectedUserType, setSelectedUserType] = useState("");
    const [showOrganizationDropdown, setShowOrganizationDropdown] = useState(false);
    let AddMemberSchema = yup.object().shape({
        organizationName: yup.string().when('addmembertype', {
            is: !tabView && 'child',
            then: yup.string().required('Please select an organization.'),
            otherwise: yup.string(),
        }),
        firstName: yup.string().required("First Name is required."),
        lastName: yup.string(),
        email: yup
            .string()
            .trim()
            .matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Invalid email address.")
            .required("Email is required."),
        userType: yup.string().required("Please select a user type."),
        addmembertype: yup.string().required("Please select a member type."),
        menu: yup.array().min(1, "Please select at least one menu option."),
    });

    return (
        <Formik
            initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                menu: MenuValue,
                organizationName: '',
                userType: '',
                addmembertype: tabView ? 'child' : '',
            }}
            onSubmit={(values) => { onSubmit(values, selectedCompanyId); setShowOrganizationDropdown(false); }}
            validationSchema={AddMemberSchema}
        >
            {({ setFieldValue, values }) => (
                <Form>
                    <Flex flexDirection="column">
                        <Flex marginBottom={3}>
                            <Flex padding={"5px 10px"} flexDirection="column">
                                <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"5px"}>Member Type</Text>
                                <Box width={"360px"}>
                                    <Select
                                        isDisabled={tabView}
                                        className="single-select"
                                        placeholder="Select Member Type"
                                        name="addmembertype"
                                        options={addmembertypeOptions}
                                        value={
                                            tabView
                                                ? { value: "child", label: "Child" }
                                                : addmembertypeOptions.find(option => option.value === values.addmembertype) || null
                                        }
                                        onChange={(addmembertype) => {
                                            {
                                                tabView ? setFieldValue('addmembertype', 'child') : setFieldValue('addmembertype', addmembertype ? addmembertype.value : null);
                                                setShowOrganizationDropdown(addmembertype?.value === "child");
                                                setFieldValue('userType', addmembertype?.value === "owner" ? "admin" : "");
                                                setFieldValue('menu', addmembertype?.value === "owner" ? adminMenu : MenuValue);
                                            }
                                        }}
                                        styles={{
                                            control: (provided, state) => ({
                                                ...provided,
                                                width: "360px",
                                            }),
                                        }}
                                    />
                                    <ErrorMessage
                                        name="addmembertype"
                                        component="div"
                                        className="custom-error-message"
                                    />
                                </Box>
                            </Flex>
                            {showOrganizationDropdown && (
                                <Flex padding={"5px 10px"} flexDirection="column">
                                    <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"5px"}>Admin Name</Text>
                                    <Box width={"360px"}>
                                        <Select
                                            className="single-select"
                                            placeholder="Admin List"
                                            name="organizationName"
                                            options={adminMemberList}
                                            onChange={(organizationName) => {
                                                const selectedValue = organizationName as { value: string };
                                                setFieldValue('organizationName', selectedValue ? selectedValue.value : null);
                                                setSelectedCompanyId(selectedValue.value);

                                            }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    width: "360px",
                                                }),
                                            }}
                                        />
                                        <ErrorMessage
                                            name="organizationName"
                                            component="div"
                                            className="custom-error-message"
                                        />
                                    </Box>
                                </Flex>
                            )}
                        </Flex>
                        <Flex>
                            <Flex padding={"5px 10px"} flexDirection="column">
                                <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"5px"}>First Name</Text>
                                <FormikInput
                                    name="firstName"
                                    placeholder="aereturn.profile.firstname"
                                />
                            </Flex>
                            <Flex padding={"5px 10px"} flexDirection="column">
                                <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"5px"}>Last Name</Text>
                                <FormikInput
                                    name="lastName"
                                    placeholder="aereturn.profile.lastname"
                                />
                            </Flex>
                        </Flex>
                        <Flex>
                            <Flex padding={"5px 10px"} flexDirection="column">
                                <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"5px"}>Email Address</Text>
                                <FormikInput
                                    name="email"
                                    type="email"
                                    placeholder="aereturn.profile.email"
                                />
                            </Flex>
                            <Flex padding={"5px 10px"} flexDirection="column">
                                <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"5px"}>User Role</Text>
                                <Box width={"360px"}>
                                    <Select
                                        className="single-select"
                                        placeholder="Select User Type"
                                        name="userType"
                                        options={usertypeOptions}
                                        onChange={(userType) => {
                                            if (typeof userType === 'string') {
                                                setFieldValue('userType', userType);
                                                setSelectedUserType(userType);
                                            } else {
                                                setFieldValue('userType', userType ? userType.value : null);
                                                setSelectedUserType(userType?.value || '');
                                            }
                                            if (userType?.value === "sdr" || userType?.value === "ae") {
                                                setFieldValue('menu', sdrAeMenu);
                                            } else {
                                                setFieldValue('menu', adminMenu);
                                            }
                                        }}
                                        value={values.addmembertype === 'owner' ? { value: 'admin', label: 'Admin' } : usertypeOptions.find((option) => option.value === values.userType) || null}
                                        isDisabled={values.addmembertype === 'owner'}
                                        styles={{
                                            control: (provided, state) => ({
                                                ...provided,
                                                width: "360px",
                                            }),
                                        }}
                                    />
                                    <ErrorMessage
                                        name="userType"
                                        component="div"
                                        className="custom-error-message"
                                    />
                                </Box>
                            </Flex>
                        </Flex>
                        <Flex>
                            <Flex padding={"5px 10px"} flexDirection="column">
                                <Text variant="bodyRegular" fontWeight={"600"} paddingBottom={"5px"}>Menu</Text>
                                <Box width={"360px"}>
                                    <Select
                                        isMulti
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Select Menu"
                                        menuPlacement="auto"
                                        name="menu"
                                        options={MenuValue}
                                        value={values.menu.map(item => MenuValue.find(option => option.value == item.value))}
                                        closeMenuOnSelect={false}
                                        onChange={(menuOptions) => {
                                            setFieldValue('menu', menuOptions);
                                            // console.log(menuOptions);
                                        }}
                                        styles={{
                                            control: (provided, state) => ({
                                                ...provided,
                                                width: "360px",
                                                height: "120px",
                                                overflow: "scroll"
                                            }),
                                        }}
                                    />
                                    <ErrorMessage
                                        name="menu"
                                        component="div"
                                        className="custom-error-message"
                                    />
                                </Box>
                            </Flex>
                        </Flex>

                        <Flex justifyContent={"flex-end"} marginRight={1} padding={"5px 10px"}>
                            <Button type="submit">Submit</Button>
                            <Button variant="secondary" type="button" onClick={() => handleClose()} marginLeft={2}>
                                Cancel
                            </Button>
                        </Flex>
                    </Flex>
                </Form>
            )}
        </Formik>
    );
};

export default AddMemberForm;
